import React, { createContext, useReducer, useEffect, useState } from 'react';
import CustLoader from '../utils/loader/CustLoader';
import httpService from './../services/httpService';
import { commonJsFuncModule as commonJsObj } from '../utils/commonFunc';

let initialState = { "lang": {}, "settingDataSet":{},"notificationDataSet":[],"promoCodeSet":[] };

const store = createContext(initialState);
const { Provider } = store;
let settingDataSet = {};
let notificationDataSet = [];
let promoCodeSet=[];
let num="";


const StateProvider = ({ children }) => {
    const [loderFlag, setLoaderFlag] = useState(false);

    let showLoader = () => {
        setLoaderFlag(true);
    }
    let hideLoader = () => {
        setLoaderFlag(false);
    }

    let fetchApi = async () => {
       // showLoader();

        let siteSettingResponse = await httpService.setModule('siteSetting').search({});

        if (siteSettingResponse.res.data.status == 'success') {
            settingDataSet = siteSettingResponse.res.data.data;
        }
        // if (commonJsObj.getCurrentUserId()) {
        //     let notificationResponse = await httpService.setModule('notification').search({ user_id: commonJsObj.getCurrentUserId(), is_read: "0" });

        //     if (notificationResponse.res.data.status == 'success') {
        //        notificationDataSet = notificationResponse.res.data.data;
        //        console.log(notificationDataSet,'notificationDataSet')
               
        //     }
        //     let promcodeNotify = await httpService.setModule('coupon').search({user_id:commonJsObj.getCurrentUserId(),is_read : "0", is_active:1 })

        //     if (promcodeNotify.res.data.status == 'success') {
        //         promoCodeSet = promcodeNotify.res.data.data;
        //     }
        // }


        hideLoader();

    }

    useEffect(() => {
      //  showLoader();
        fetchApi();

    }, []);


    const [state, dispatch] = useReducer((state, action) => {
     
        switch (action.type) {
            case 'storeData':
                let newState = {};
           
                 newState = { ...initialState, lang: "en", "settingDataSet": settingDataSet, "notificationInfo": (action.payload) ? action.payload.notficationData : [], "promoCodeInfo": (action.payload) ? action.payload.promoCodeInfo : [],"sitePaymentInfo":(action.payload) ? action.payload.sitePaymentInfo : [] };// do something with the action

                return newState;
            
            default:
               return state;
        };
    }, initialState);

    return (<>
        {loderFlag ? <CustLoader /> : ''}
        {!loderFlag && <Provider value={{ state, dispatch }}>
            {children}
        </Provider>}
    </>);
};

export { store, StateProvider }