import * as React from "react";

export const siteInsallInfoIcon = (
  <svg enable-background="new 0 0 48 48" height="48px" id="Layer_3" version="1.1" viewBox="0 0 48 48" width="48px">
    <g>
      <path d="M26.22,13.234c0.572,0,1.097-0.202,1.57-0.606c0.469-0.404,0.757-0.894,0.86-1.466   c0.098-0.576-0.024-1.062-0.38-1.466c-0.354-0.408-0.818-0.61-1.392-0.61c-0.576,0-1.1,0.202-1.569,0.61   c-0.474,0.403-0.763,0.89-0.86,1.466c-0.071,0.573,0.066,1.062,0.402,1.466C25.19,13.032,25.648,13.234,26.22,13.234z" fill="#008eff"/>
      <path d="M30.622,27.4c-0.053,0.075-0.104,0.14-0.156,0.216c-0.312,0.396-1.475,1.795-2.984,2.632   c-0.01,0.004-0.019,0.008-0.024,0.014c-0.132,0.07-0.267,0.123-0.401,0.185c-0.398,0.143-0.771,0.179-0.891,0.188   c-0.339-0.044-0.522-0.281-0.522-0.73c0-0.371,0.108-1.104,0.328-2.205l0.254-1.111l1.035-4.968   c0.168-0.846,0.258-1.316,0.278-1.409l0.226-1.162c0.133-0.719,0.201-1.194,0.201-1.426c0-0.444-0.11-0.781-0.287-1.055   c-0.016-0.04-0.037-0.08-0.066-0.122c-0.026-0.032-0.054-0.06-0.082-0.089c-0.031-0.034-0.046-0.063-0.088-0.098   c-0.441-0.36-0.853-0.464-1.137-0.487l0.005-0.008c0,0-1.867-0.141-4.634,1.682c-0.039,0.026-0.063,0.045-0.1,0.07   c-0.75,0.474-1.348,0.949-1.764,1.332c-0.318,0.272-0.562,0.525-0.744,0.762l-0.009,0.011l0.001-0.001   c-0.235,0.311-0.359,0.593-0.359,0.843c0,0.197,0.159,0.394,0.476,0.591c0,0,1.188-1.534,3.185-2.532   c0.15-0.065,0.534-0.224,0.871-0.312c0.158-0.036,0.455-0.064,0.646,0.093c0.136,0.142,0.226,0.339,0.226,0.646   c0,0.279-0.041,0.618-0.127,1.023l-0.201,0.957l-0.252,1.196l-0.986,4.728c-0.504,2.442-0.757,3.903-0.757,4.379   c0,1.123,0.615,1.685,1.843,1.685c0.557,0,1.13-0.101,1.717-0.281c0.004,0,0.006,0.002,0.01,0.004   c0.04-0.014,0.076-0.029,0.115-0.043c0.098-0.031,0.195-0.068,0.293-0.105c2.771-1.031,4.595-3.108,5.146-3.804   c0.049-0.059,0.091-0.114,0.13-0.167c0.031-0.043,0.051-0.072,0.051-0.072h-0.002c0.14-0.206,0.217-0.377,0.217-0.505   C31.299,27.766,31.068,27.587,30.622,27.4z" fill="#008eff"/>
      <path d="M18.732,31.873l1.435-1.436l-2.604-2.606l-1.435,1.438c-0.836-0.554-1.768-0.967-2.764-1.226v-2.147H8.74   v2.147c-1.019,0.264-1.973,0.688-2.822,1.26l-1.378-1.378l-2.605,2.603l1.401,1.402c-0.536,0.82-0.938,1.734-1.19,2.708H0v4.62   h2.146c0.258,0.997,0.671,1.932,1.225,2.768l-1.436,1.437l2.605,2.603l1.437-1.436c0.837,0.555,1.773,0.97,2.773,1.228V48h4.605   v-2.143c0.976-0.252,1.892-0.656,2.715-1.192l1.493,1.493l2.604-2.604l-1.47-1.472c0.574-0.854,1-1.814,1.263-2.838h2.143v-4.591   H19.96C19.703,33.652,19.289,32.713,18.732,31.873z M11.051,40.737c-2.092,0-3.788-1.696-3.788-3.788s1.696-3.789,3.788-3.789   s3.789,1.697,3.789,3.789S13.143,40.737,11.051,40.737z" fill="#008eff"/>
      <path d="M24,0.125C10.814,0.125,0.125,10.814,0.125,24c0,1.757,0.195,3.468,0.555,5.116   c0.968-1.265,2.16-2.344,3.521-3.182C4.14,25.298,4.104,24.653,4.104,24C4.104,13.012,13.012,4.104,24,4.104   S43.896,13.012,43.896,24S34.988,43.896,24,43.896c-0.653,0-1.297-0.036-1.935-0.098c-0.838,1.361-1.917,2.554-3.182,3.521   c1.649,0.36,3.359,0.556,5.116,0.556c13.186,0,23.875-10.689,23.875-23.875S37.186,0.125,24,0.125z" fill="#008eff"/>
    </g>
  </svg>
);

export const dashboardIcon = (
  <svg height="48" viewBox="0 0 48 48" width="48">
    <path d="M0 0h48v48H0z" fill="none" />
    <path d="M6 26h16V6H6v20zm0 16h16V30H6v12zm20 0h16V22H26v20zm0-36v12h16V6H26z" />
  </svg>
);

export const userRoleIcon = (
  <svg height="32px" id="svg2" version="1.1" viewBox="0 0 32 32" width="32px">
    <g id="background">
      <rect fill="none" height="32" width="32" />
    </g>
    <g id="group_x5F_full_x5F_add">
      <path d="M22.381,7.52c0.064,0.034,0.133,0.067,0.193,0.105C23,7.856,23.48,8,24,8c1.658,0,3-1.343,3-3s-1.342-3-3-3   c-1.656,0-3,1.343-3,3C21,6.06,21.553,6.985,22.381,7.52z M8,20.001c0-2,0-10,0-10S8.005,9.551,8.238,9C6.159,9,4,9,4,9   s-2,0-2,2.001v7c0,2,2,2,2,2v10h6v-8C10,22.001,8,22.001,8,20.001z M16,8c1.657,0,3-1.343,3-3s-1.343-3-3-3s-3,1.343-3,3   S14.343,8,16,8z M8,8c0.52,0,1.001-0.144,1.427-0.376c0.059-0.036,0.125-0.068,0.188-0.103C10.446,6.988,11,6.062,11,5   c0-1.657-1.343-3-3-3S5,3.343,5,5S6.343,8,8,8z M30,17.351c0-2.261,0-6.35,0-6.35S30,9,28,9h-4.238   C23.996,9.551,24,10.001,24,10.001s0,1.888,0,4.059c-0.328-0.036-0.662-0.059-1-0.059s-0.67,0.022-1,0.059c0-1.632,0-3.059,0-3.059   S22,9,20,9c-0.5,0-8,0-8,0s-2,0-2,2.001v7c0,2,2,2,2,2v10h5.35C18.895,31.249,20.859,32,23,32c4.971,0,9-4.028,9-8.999   C32,20.859,31.248,18.896,30,17.351z M23,30c-3.865-0.008-6.994-3.135-7-6.999c0.006-3.865,3.135-6.994,7-7   c3.865,0.006,6.992,3.135,7,7C29.992,26.865,26.865,29.992,23,30z" />
      <g>
        <polygon points="28,22 24.002,22 24.002,18 22,18 22,22 18,22 18,24 22,24 22,28 24.002,28 24.002,24 28,24   " />
      </g>
    </g>
  </svg>
);

export const userIcon = (
  <svg
    enableBackground="new 0 0 32 32"
    height="32px"
    id="svg2"
    version="1.1"
    viewBox="0 0 32 32"
    width="32px"
  >
    <g id="background">
      <rect fill="none" height="32" width="32" />
    </g>
    <g id="user_x5F_half_x5F_security">
      <path d="M16,8c2.209,0,4-1.791,4-4s-1.791-4-4-4s-4,1.791-4,4S13.791,8,16,8z M24,14.059C24,12.821,24,12,24,12s0-2-2-2   c-1,0-12,0-12,0s-2,0-2,2v14c0,2,2,2,2,2v4h12v-0.059C22.329,31.978,22.661,32,23,32c4.971-0.002,8.998-4.029,9-9   C31.998,18.366,28.499,14.556,24,14.059z M23,29.883c-3.801-0.009-6.876-3.084-6.884-6.883c0.008-3.801,3.083-6.876,6.884-6.884   c3.799,0.008,6.874,3.083,6.883,6.884C29.874,26.799,26.799,29.874,23,29.883z" />
      <path d="M26.144,22c0.008-0.131,0.014-0.267,0.014-0.409c-0.006-0.678-0.088-1.492-0.556-2.259   c-0.457-0.779-1.438-1.36-2.602-1.333c-1.165-0.027-2.148,0.554-2.605,1.333c-0.47,0.767-0.552,1.581-0.556,2.259   c0,0.143,0.006,0.278,0.014,0.409H19v5h8v-5H26.144z M21.837,21.591c-0.004-0.478,0.093-0.958,0.254-1.198   c0.173-0.227,0.27-0.366,0.909-0.394c0.637,0.027,0.73,0.165,0.904,0.394c0.162,0.24,0.259,0.723,0.253,1.198   c0,0.145-0.008,0.28-0.019,0.409h-2.282C21.846,21.869,21.837,21.732,21.837,21.591z" />
    </g>
  </svg>
);
export const categoryIcon = (
  <svg viewBox="0 0 32 32">
    <g>
      <g>
        <path d="M11,21H3c-0.5527344,0-1-0.4472656-1-1v-8c0-0.5527344,0.4472656-1,1-1h8c0.5527344,0,1,0.4472656,1,1v8    C12,20.5527344,11.5527344,21,11,21z M4,19h6v-6H4V19z" />
      </g>
      <g>
        <path d="M29,28h-8c-0.5527344,0-1-0.4472656-1-1v-8c0-0.5527344,0.4472656-1,1-1h8c0.5527344,0,1,0.4472656,1,1v8    C30,27.5527344,29.5527344,28,29,28z M22,26h6v-6h-6V26z" />
      </g>
      <g>
        <path d="M29,12h-8c-0.5527344,0-1-0.4472656-1-1V3c0-0.5527344,0.4472656-1,1-1h8c0.5527344,0,1,0.4472656,1,1v8    C30,11.5527344,29.5527344,12,29,12z M22,10h6V4h-6V10z" />
      </g>
      <g>
        <path d="M16,32c-0.5527344,0-1-0.4472656-1-1V1c0-0.5527344,0.4472656-1,1-1s1,0.4472656,1,1v30    C17,31.5527344,16.5527344,32,16,32z" />
      </g>
      <g>
        <path d="M16,17h-5c-0.5527344,0-1-0.4472656-1-1s0.4472656-1,1-1h5c0.5527344,0,1,0.4472656,1,1S16.5527344,17,16,17z" />
      </g>
      <g>
        <path d="M21,8h-5c-0.5527344,0-1-0.4472656-1-1s0.4472656-1,1-1h5c0.5527344,0,1,0.4472656,1,1S21.5527344,8,21,8z" />
      </g>
      <g>
        <path d="M21,24h-5c-0.5527344,0-1-0.4472656-1-1s0.4472656-1,1-1h5c0.5527344,0,1,0.4472656,1,1S21.5527344,24,21,24z" />
      </g>
    </g>
  </svg>
);

export const itemsIcon = (
  <svg
    enable-background="new 0 0 24 24"
    height="24px"
    id="Layer_1"
    version="1.1"
    viewBox="0 0 24 24"
    width="24px"
  >
    <g>
      <path d="M20.6,5.7c0-0.2-0.1-0.3-0.2-0.4l-8.1-4.7c-0.1-0.1-0.3-0.1-0.5,0L3.6,5.3C3.5,5.4,3.4,5.5,3.4,5.7c0,0.2,0.1,0.3,0.2,0.4   l8.1,4.7c0.1,0,0.2,0.1,0.2,0.1s0.2,0,0.2-0.1l8.1-4.7C20.5,6,20.6,5.8,20.6,5.7z" />
      <path d="M10.6,13.2L2.5,8.5C2.3,8.4,2.1,8.4,2,8.5C1.9,8.6,1.8,8.8,1.8,8.9v9.4c0,0.2,0.1,0.3,0.2,0.4l8.1,4.7   c0.1,0,0.2,0.1,0.2,0.1s0.2,0,0.2-0.1c0.1-0.1,0.2-0.2,0.2-0.4v-9.4C10.9,13.4,10.8,13.3,10.6,13.2z" />
      <path d="M22,8.5c-0.1-0.1-0.3-0.1-0.5,0l-8.1,4.7c-0.1,0.1-0.2,0.2-0.2,0.4V23c0,0.2,0.1,0.3,0.2,0.4c0.1,0,0.2,0.1,0.2,0.1   s0.2,0,0.2-0.1l8.1-4.7c0.1-0.1,0.2-0.2,0.2-0.4V8.9C22.2,8.8,22.1,8.6,22,8.5z" />
    </g>
  </svg>
);

export const arrowIcon = (
  <svg
    stroke="currentColor"
    fill="currentColor"
    strokeWidth="0"
    viewBox="0 0 24 24"
    className="cr-sidebar__nav-item-icon"
    height="1em"
    width="1em"
    xmlns="http://www.w3.org/2000/svg"
    style={{
      padding: "0px",
      transform: "rotate(-90deg)",
      transitionDuration: "0.3s",
      transitionProperty: "transform",
    }}
  >
    <path d="M7.41 7.84L12 12.42l4.59-4.58L18 9.25l-6 6-6-6z"></path>
  </svg>
);

export const hamburgerIcon = (
  <>
    <svg width="24" className="nav-open" height="24" viewBox="0 0 24 24">
      <path data-name="Path 2012" d="M0 0h24v24H0z" fill="none"></path>
      <path
        data-name="Path 2013"
        d="M3 18h18v-2H3zm0-5h18v-2H3zm0-7v2h18V6z"
        fill="#006"
      ></path>
    </svg>
    <svg
      className="nav-close"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      id="baseline-clear-24px"
    >
      <path
        data-name="Path 926"
        d="M19,6.41,17.59,5,12,10.59,6.41,5,5,6.41,10.59,12,5,17.59,6.41,19,12,13.41,17.59,19,19,17.59,13.41,12Z"
        fill="#006"
        id="Path_926"
      ></path>
      <path
        data-name="Path 927"
        d="M0,0H24V24H0Z"
        fill="none"
        id="Path_927"
      ></path>
    </svg>
  </>
);

export const siteIcon = (
  <svg
    enableBackground="new 0 0 32 32"
    height="32px"
    id="svg2"
    version="1.1"
    viewBox="0 0 32 32"
    width="32px"
  >
    <g id="background">
      <rect fill="none" height="32" width="32" />
    </g>
    <g id="social_x5F_network">
      <g>
        <g>
          <path d="M16,28v4h12c0,0,0-2,0-4s-2-2-2-2h-4h-4C16,26,16,28,16,28z M4,16H0v12c0,0,2,0,4,0s2-2,2-2v-4v-4C6,16,4,16,4,16z M16,4     V0H4c0,0,0,2,0,4s2,2,2,2h4h4C16,6,16,4,16,4z M28,4c-2,0-2,2-2,2v4v4c0,2,2,2,2,2h4V4C32,4,30,4,28,4z M22,6     c-2.209,0-4,1.791-4,4c0,1.861,1.278,3.412,3,3.858v4.283c-0.363,0.094-0.704,0.238-1.021,0.424l-6.545-6.545     C13.785,11.425,14,10.74,14,10c0-2.209-1.791-4-4-4s-4,1.791-4,4c0,1.861,1.278,3.412,3,3.858v4.283C7.278,18.588,6,20.139,6,22     c0,2.209,1.791,4,4,4s4-1.791,4-4c0-1.861-1.278-3.412-3-3.858v-4.283c0.364-0.094,0.705-0.238,1.021-0.425l6.545,6.546     C18.215,20.574,18,21.259,18,22c0,2.209,1.791,4,4,4s4-1.791,4-4c0-1.861-1.278-3.412-3-3.858v-4.283     c1.722-0.446,3-1.997,3-3.858C26,7.791,24.209,6,22,6z" />
        </g>
      </g>
    </g>
  </svg>
);

export const emailContentIcon = (
  <svg
    enableBackground="new 0 0 32 32"
    height="32px"
    id="svg2"
    version="1.1"
    viewBox="0 0 32 32"
    width="32px"
  >
    <g id="background">
      <rect fill="none" height="32" width="32" />
    </g>
    <g id="comment_x5F_edit">
      <path d="M26,8H8v2h18V8z M26,12H8v2h15h3V12z M30,17.35V2H2l0,28h2.414l6.002-6h3.643c0.498,4.498,4.309,7.998,8.941,8   c4.97-0.002,8.998-4.03,9-9C31.999,20.858,31.248,18.895,30,17.35z M14.059,22H9.584L4,27.583V4h24v11.518   C26.569,14.561,24.851,14,23,14c-2.143,0-4.106,0.751-5.652,2H8v2h7.517C14.734,19.169,14.221,20.531,14.059,22z M23,29.882   c-3.801-0.008-6.876-3.083-6.885-6.882c0.009-3.801,3.084-6.876,6.885-6.885c3.799,0.009,6.874,3.084,6.882,6.885   C29.874,26.799,26.799,29.874,23,29.882z" />
      <polygon points="22,27 19,27 19,24  " />
      <rect
        height="4.243"
        transform="matrix(-0.7071 0.7071 -0.7071 -0.7071 56.5269 20.5858)"
        width="7.071"
        x="20.464"
        y="19.879"
      />
    </g>
  </svg>
);

export const subscriptionPackIcon = (
  <svg
    enableBackground="new 0 0 48 48"
    height="48px"
    version="1.1"
    viewBox="0 0 48 48"
    width="48px"
  >
    <g id="Guides" />
    <g id="Layer_3">
      <g>
        <polygon
          fill="#b9b9b9"
          points="43.101,0 39.21,0 8.825,0 4.899,0 3.812,0 3.812,12.652 8.825,12.652 8.825,5.047 39.21,5.047     39.21,12.652 44.188,12.652 44.188,0   "
        />
        <path
          d="M25.038,22.761c-1.577-0.594-2.226-0.984-2.226-1.596c0-0.52,0.39-1.04,1.596-1.04    c1.336,0,2.192,0.427,2.673,0.632l0.538-2.098c-0.613-0.297-1.449-0.557-2.691-0.613v-1.633h-1.819v1.763    c-1.986,0.39-3.137,1.67-3.137,3.304c0,1.8,1.355,2.728,3.341,3.396c1.374,0.464,1.968,0.909,1.968,1.616    c0,0.741-0.724,1.148-1.783,1.148c-1.206,0-2.301-0.39-3.081-0.815l-0.556,2.172c0.705,0.407,1.911,0.742,3.155,0.798v1.762h1.819    v-1.893c2.133-0.37,3.303-1.781,3.303-3.433C28.139,24.561,27.247,23.541,25.038,22.761z"
          fill="#b9b9b9"
        />
        <path
          d="M35.776,34.069v-5.496v-8.779v-1.998V8.018H12.224v8.517v3.259v8.779v2.208v4.315H5L24,48l19-12.903    h-7.224V34.069z M14.826,33.347v-1.789v-0.776v-2.208v-8.779v-3.259v-1.882c0.246,0.057,0.499,0.094,0.762,0.094    c1.858,0,3.365-1.506,3.365-3.365c0-0.241-0.028-0.475-0.076-0.701h10.245c-0.047,0.227-0.075,0.46-0.075,0.701    c0,1.858,1.507,3.365,3.364,3.365c0.165,0,0.324-0.025,0.481-0.048v3.098v1.998v8.779v1.896v2.832v1.796H14.826V33.347z"
          fill="#b9b9b9"
        />
      </g>
    </g>
  </svg>
);

export const performanceIcon = (
  <svg
    enableBackground="new 0 0 64 64"
    height="64px"
    id="Layer_1"
    version="1.1"
    viewBox="0 0 64 64"
    width="64px"
  >
    <g>
      <path d="M29.568,11.75c-12.482-0.001-22.6,10.117-22.599,22.599c-0.001,12.482,10.117,22.6,22.599,22.599   c12.482,0.001,22.6-10.117,22.599-22.599l-22.599-0.001V11.75z" />
      <path d="M34.432,7.052v22.6h22.599C57.032,17.169,46.915,7.05,34.432,7.052z" />
    </g>
  </svg>
);
export const generalIcon = (
  <svg height="48" viewBox="0 0 48 48" width="48">
    <path d="M0 0h48v48H0z" fill="none" />
    <path d="M10 4c0-1.1-.89-2-2-2s-2 .9-2 2v8H2v12h12V12h-4V4zm8 28c0 2.61 1.68 4.81 4 5.63V46h4v-8.37c2.32-.83 4-3.02 4-5.63v-4H18v4zM2 32c0 2.61 1.68 4.81 4 5.63V46h4v-8.37c2.32-.83 4-3.02 4-5.63v-4H2v4zm40-20V4c0-1.1-.89-2-2-2s-2 .9-2 2v8h-4v12h12V12h-4zM26 4c0-1.1-.89-2-2-2s-2 .9-2 2v8h-4v12h12V12h-4V4zm8 28c0 2.61 1.68 4.81 4 5.63V46h4v-8.37c2.32-.83 4-3.02 4-5.63v-4H34v4z" />
  </svg>
);

export const ticketIcon = (
  <svg id="Layer_1_1_" version="1.1" viewBox="0 0 64 64">
    <g>
      <path d="M62,39c-2.757,0-5-2.243-5-5v-1H42.425l10.989-11l-0.706-0.707c-1.972-1.973-1.972-5.184,0-7.157l0.706-0.707L40.583,0.585   l-0.708,0.708c-0.955,0.956-2.224,1.482-3.574,1.482c-1.35,0-2.619-0.526-3.574-1.482L32.02,0.585L0.637,32l0.706,0.707   c1.972,1.973,1.972,5.184,0,7.157l-0.706,0.707L7,46.941V57h1c2.757,0,5,2.243,5,5v1h44v-1c0-2.757,2.243-5,5-5h1V39H62z    M3.414,40.521c1.875-2.5,1.875-5.972,0-8.472L32.069,3.365c1.214,0.915,2.686,1.41,4.232,1.41c1.547,0,3.018-0.495,4.232-1.41   l10.103,10.113c-1.875,2.5-1.875,5.972,0,8.472L39.598,33h-2.882l1.72-1.722l-1.415-1.413L33.889,33h-7.475l4.293-4.293   l-1.414-1.414L23.586,33h-4.172l19.293-19.293l-1.414-1.414L16.586,33H13v1c0,2.626-2.042,4.762-4.617,4.961   c0.526-1.992,0.47-3.999-0.183-5.988l0.979-0.981l-1.415-1.413L5.876,32.47l0.237,0.608c0.831,2.128,0.831,4.287,0,6.415   l-0.237,0.608L7,41.227v2.885L3.414,40.521z M61,55.071c-3.061,0.44-5.489,2.868-5.929,5.929H14.929   c-0.44-3.061-2.868-5.489-5.929-5.929V40.929c3.061-0.44,5.489-2.868,5.929-5.929h40.143c0.44,3.061,2.868,5.489,5.929,5.929   V55.071z" />
      <rect height="2" width="3" x="23" y="57" />
      <path d="M13,52.366V50h-2v3.654l0.599,0.262c2.069,0.906,3.579,2.416,4.485,4.485L16.346,59H19v-2h-1.366   C16.599,54.957,15.043,53.401,13,52.366z" />
      <rect height="2" width="3" x="23" y="37" />
      <path d="M13,43.634c2.043-1.035,3.599-2.59,4.634-4.634H19v-2h-2.654l-0.262,0.599c-0.906,2.069-2.416,3.579-4.485,4.485L11,42.346   V46h2V43.634z" />
      <rect height="2" width="3" x="44" y="57" />
      <path d="M57,52.366c-2.043,1.035-3.599,2.59-4.634,4.634H51v2h2.654l0.262-0.599c0.906-2.069,2.416-3.579,4.485-4.485L59,53.654V50   h-2V52.366z" />
      <rect height="2" width="10" x="30" y="37" />
      <rect height="2" width="10" x="30" y="57" />
      <rect height="2" width="3" x="44" y="37" />
      <path d="M53.916,37.599L53.654,37H51v2h1.366c1.035,2.043,2.59,3.599,4.634,4.634V46h2v-3.654l-0.599-0.262   C56.332,41.178,54.822,39.668,53.916,37.599z" />
      <rect
        height="2"
        transform="matrix(0.7066 -0.7076 0.7076 0.7066 -15.7208 16.7981)"
        width="3.029"
        x="10.882"
        y="26.357"
      />
      <rect
        height="2"
        transform="matrix(0.7066 -0.7076 0.7076 0.7066 -6.632 37.2902)"
        width="3.029"
        x="40.139"
        y="25.643"
      />
      <path d="M45.85,21.027l-0.979,0.981l1.415,1.413l1.888-1.891l-0.237-0.608c-0.831-2.128-0.831-4.287,0-6.415l0.237-0.608   l-2.602-2.605l-1.415,1.413l1.693,1.695C45.128,16.603,45.128,18.826,45.85,21.027z" />
      <rect
        height="2"
        transform="matrix(0.7066 -0.7076 0.7076 0.7066 -8.2156 19.8995)"
        width="10.097"
        x="14.841"
        y="18.857"
      />
      <rect
        height="2"
        transform="matrix(0.7066 -0.7076 0.7076 0.7066 -0.7105 23.0006)"
        width="3.029"
        x="25.867"
        y="11.357"
      />
      <path d="M32.993,8.156c2.199,0.722,4.419,0.722,6.617,0l1.693,1.694l1.415-1.414L40.114,5.83l-0.609,0.239   c-2.126,0.832-4.281,0.832-6.407,0L32.489,5.83l-1.89,1.892l1.415,1.413L32.993,8.156z" />
      <path d="M43.207,45.501l-5.699-0.798L35,39.806l-2.507,4.897l-5.699,0.798l4.127,3.877l-0.964,5.42L35,52.242l5.043,2.556   l-0.964-5.42L43.207,45.501z M37.373,51.202L35,50l-2.373,1.202l0.452-2.54l-1.873-1.76l2.593-0.363L35,44.194l1.201,2.345   l2.593,0.363l-1.873,1.76L37.373,51.202z" />
      <path d="M21,41.646l-1.915,4.08l-4.16,0.636l3.006,3.081l-0.722,4.429L21,51.776l3.791,2.096l-0.722-4.429l3.006-3.081l-4.16-0.636   L21,41.646z M22.154,50.128L21,49.491l-1.154,0.638l0.222-1.36l-0.994-1.019l1.368-0.209L21,46.354l0.557,1.188l1.368,0.209   l-0.994,1.019L22.154,50.128z" />
      <path d="M52.068,49.442l3.006-3.081l-4.16-0.636L49,41.646l-1.915,4.08l-4.16,0.636l3.006,3.081l-0.722,4.429L49,51.776   l3.791,2.096L52.068,49.442z M50.154,50.128L49,49.491l-1.154,0.638l0.222-1.36l-0.994-1.019l1.368-0.209L49,46.354l0.557,1.188   l1.368,0.209l-0.994,1.019L50.154,50.128z" />
      <rect
        height="2"
        transform="matrix(0.7071 -0.7071 0.7071 0.7071 -3.5122 32.5208)"
        width="12.728"
        x="31.136"
        y="19.5"
      />
    </g>
  </svg>
);

export const titleSiteIcon = (
  <svg id="Icons" version="1.1" viewBox="0 0 32 32">
    <path
      fill="#00e7e7"
      d="M29,22h-2v-3c0-0.6-0.4-1-1-1h-9v-2h6c1.7,0,3-1.3,3-3V4c0-1.7-1.3-3-3-3H9C7.3,1,6,2.3,6,4v9c0,1.7,1.3,3,3,3h6v2H6  c-0.6,0-1,0.4-1,1v3H3c-0.6,0-1,0.4-1,1v6c0,0.6,0.4,1,1,1h6c0.6,0,1-0.4,1-1v-6c0-0.6-0.4-1-1-1H7v-2h8v2h-2c-0.6,0-1,0.4-1,1v6  c0,0.6,0.4,1,1,1h6c0.6,0,1-0.4,1-1v-6c0-0.6-0.4-1-1-1h-2v-2h8v2h-2c-0.6,0-1,0.4-1,1v6c0,0.6,0.4,1,1,1h6c0.6,0,1-0.4,1-1v-6  C30,22.4,29.6,22,29,22z"
    />
  </svg>
);

export const proposalIcon = (
  <svg viewBox="0 0 64 64" xmlns="http://www.w3.org/2000/svg">
    <g id="Contact_approved">
      <path d="M25.4792,25.8422a1.3449,1.3449,0,0,0,1.3438-1.3437V15.2626l-10.1494,10.58Z" />
      <path d="M40.3579,14.1066c0-.0847.011-.1668.0127-.2512H29.4714a1.28,1.28,0,0,1,.0508.2515V24.4985a4.0473,4.0473,0,0,1-4.043,4.0429h-10.1V58.9372a2.6736,2.6736,0,0,0,2.6736,2.6736H51.4941a2.6736,2.6736,0,0,0,2.6736-2.6736V25.8928a11.8652,11.8652,0,0,1-13.81-11.7862Zm-1.79,40.4524H34.5857a4.354,4.354,0,0,1-4.3272-3.9218L24.7712,54.33a1.35,1.35,0,0,1-1.5078-2.24l6.05-4.07a2.325,2.325,0,0,1,3.624,1.93v.2627A1.65,1.65,0,0,0,34.5857,51.86h3.9824a1.35,1.35,0,1,1,0,2.6992Zm0-11.8642H22.9812a1.35,1.35,0,0,1,0-2.6993H38.5681a1.35,1.35,0,0,1,0,2.6993ZM46.09,54.559H44.0388a1.35,1.35,0,0,1,0-2.6992H46.09a1.35,1.35,0,0,1,0,2.6992Zm0-18.1328H22.9812a1.35,1.35,0,0,1,0-2.6992H46.09a1.35,1.35,0,0,1,0,2.6992Z" />
      <path d="M14.8376,13.4955V4.26L4.6885,14.8393h8.8054A1.3449,1.3449,0,0,0,14.8376,13.4955Z" />
      <path d="M28.1731,11.1555H40.7419a11.8693,11.8693,0,0,1,1.4405-3.3643V5.5263a2.6736,2.6736,0,0,0-2.6736-2.6736H17.4861a1.2745,1.2745,0,0,1,.0508.2512V13.4955a4.0473,4.0473,0,0,1-4.043,4.043h-10.1v30.396a2.6736,2.6736,0,0,0,2.6736,2.6736h6.6118V27.192a2.7,2.7,0,0,1,.7515-1.8692L26.2246,11.9863A2.7,2.7,0,0,1,28.1731,11.1555Z" />
      <path d="M52.3044,4.86a9.2465,9.2465,0,1,0,9.2464,9.2465A9.2465,9.2465,0,0,0,52.3044,4.86Zm5.2832,7.1384L52.31,18.09a1.351,1.351,0,0,1-1.02.4658h-.0039a1.3517,1.3517,0,0,1-1.0215-.4707l-2.4893-2.9043a1.3505,1.3505,0,0,1,2.0508-1.7578l1.47,1.7148,4.251-4.9072a1.35,1.35,0,1,1,2.041,1.7676Z" />
    </g>
  </svg>
);

export const couponIcon = (
  <svg ariaHidden="true" focusable="false" dataPrefix="fas" data-icon="tags" className="svg-inline--fa fa-tags fa-w-20" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512"><path fill="currentColor" d="M497.941 225.941L286.059 14.059A48 48 0 0 0 252.118 0H48C21.49 0 0 21.49 0 48v204.118a48 48 0 0 0 14.059 33.941l211.882 211.882c18.744 18.745 49.136 18.746 67.882 0l204.118-204.118c18.745-18.745 18.745-49.137 0-67.882zM112 160c-26.51 0-48-21.49-48-48s21.49-48 48-48 48 21.49 48 48-21.49 48-48 48zm513.941 133.823L421.823 497.941c-18.745 18.745-49.137 18.745-67.882 0l-.36-.36L527.64 323.522c16.999-16.999 26.36-39.6 26.36-63.64s-9.362-46.641-26.36-63.64L331.397 0h48.721a48 48 0 0 1 33.941 14.059l211.882 211.882c18.745 18.745 18.745 49.137 0 67.882z"></path></svg>
);

export const keepIcon = (
  <svg aria-hidden="true" focusable="false" data-prefix="far" data-icon="sticky-note" class="svg-inline--fa fa-sticky-note fa-w-14" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path fill="currentColor" d="M448 348.106V80c0-26.51-21.49-48-48-48H48C21.49 32 0 53.49 0 80v351.988c0 26.51 21.49 48 48 48h268.118a48 48 0 0 0 33.941-14.059l83.882-83.882A48 48 0 0 0 448 348.106zm-128 80v-76.118h76.118L320 428.106zM400 80v223.988H296c-13.255 0-24 10.745-24 24v104H48V80h352z"></path></svg>
);
export const rightIcon = (
  <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="arrow-circle-left" class="svg-inline--fa fa-arrow-circle-left fa-w-16" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path fill="currentColor" d="M256 504C119 504 8 393 8 256S119 8 256 8s248 111 248 248-111 248-248 248zm28.9-143.6L209.4 288H392c13.3 0 24-10.7 24-24v-16c0-13.3-10.7-24-24-24H209.4l75.5-72.4c9.7-9.3 9.9-24.8.4-34.3l-11-10.9c-9.4-9.4-24.6-9.4-33.9 0L107.7 239c-9.4 9.4-9.4 24.6 0 33.9l132.7 132.7c9.4 9.4 24.6 9.4 33.9 0l11-10.9c9.5-9.5 9.3-25-.4-34.3z"></path></svg>
);
export const crossIcon = (
  <>

    <svg
      className="nav-close"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      id="baseline-clear-24px"
    >
      <path
        data-name="Path 926"
        d="M19,6.41,17.59,5,12,10.59,6.41,5,5,6.41,10.59,12,5,17.59,6.41,19,12,13.41,17.59,19,19,17.59,13.41,12Z"
        fill="#006"
        id="Path_926"
      ></path>
      <path
        data-name="Path 927"
        d="M0,0H24V24H0Z"
        fill="none"
        id="Path_927"
      ></path>
    </svg>
  </>
)
export const plusIcon = (
  <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="plus" class="svg-inline--fa fa-plus fa-w-14" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path fill="currentColor" d="M416 208H272V64c0-17.67-14.33-32-32-32h-32c-17.67 0-32 14.33-32 32v144H32c-17.67 0-32 14.33-32 32v32c0 17.67 14.33 32 32 32h144v144c0 17.67 14.33 32 32 32h32c17.67 0 32-14.33 32-32V304h144c17.67 0 32-14.33 32-32v-32c0-17.67-14.33-32-32-32z"></path></svg>
)
export const pinIcon = (
  <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="thumbtack" class="svg-inline--fa fa-thumbtack fa-w-12" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512"><path fill="currentColor" d="M298.028 214.267L285.793 96H328c13.255 0 24-10.745 24-24V24c0-13.255-10.745-24-24-24H56C42.745 0 32 10.745 32 24v48c0 13.255 10.745 24 24 24h42.207L85.972 214.267C37.465 236.82 0 277.261 0 328c0 13.255 10.745 24 24 24h136v104.007c0 1.242.289 2.467.845 3.578l24 48c2.941 5.882 11.364 5.893 14.311 0l24-48a8.008 8.008 0 0 0 .845-3.578V352h136c13.255 0 24-10.745 24-24-.001-51.183-37.983-91.42-85.973-113.733z"></path></svg>
)
export const trashIcon = (
  <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="trash" class="svg-inline--fa fa-trash fa-w-14" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path fill="currentColor" d="M432 32H312l-9.4-18.7A24 24 0 0 0 281.1 0H166.8a23.72 23.72 0 0 0-21.4 13.3L136 32H16A16 16 0 0 0 0 48v32a16 16 0 0 0 16 16h416a16 16 0 0 0 16-16V48a16 16 0 0 0-16-16zM53.2 467a48 48 0 0 0 47.9 45h245.8a48 48 0 0 0 47.9-45L416 128H32z"></path></svg>
)
export const colorPicker = (
  <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="palette" class="svg-inline--fa fa-palette fa-w-16" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path fill="currentColor" d="M204.3 5C104.9 24.4 24.8 104.3 5.2 203.4c-37 187 131.7 326.4 258.8 306.7 41.2-6.4 61.4-54.6 42.5-91.7-23.1-45.4 9.9-98.4 60.9-98.4h79.7c35.8 0 64.8-29.6 64.9-65.3C511.5 97.1 368.1-26.9 204.3 5zM96 320c-17.7 0-32-14.3-32-32s14.3-32 32-32 32 14.3 32 32-14.3 32-32 32zm32-128c-17.7 0-32-14.3-32-32s14.3-32 32-32 32 14.3 32 32-14.3 32-32 32zm128-64c-17.7 0-32-14.3-32-32s14.3-32 32-32 32 14.3 32 32-14.3 32-32 32zm128 64c-17.7 0-32-14.3-32-32s14.3-32 32-32 32 14.3 32 32-14.3 32-32 32z"></path></svg>
)

export const notificationBell = (

  <svg version="1.1" id="Capa_1" x="0px" y="0px"
    viewBox="0 0 611.999 611.999" style={{
      enableBackground: "new 0 0 611.999 611.999",
    }}   >
    <g>
      <g>
        <g>
          <path d="M570.107,500.254c-65.037-29.371-67.511-155.441-67.559-158.622v-84.578c0-81.402-49.742-151.399-120.427-181.203
      C381.969,34,347.883,0,306.001,0c-41.883,0-75.968,34.002-76.121,75.849c-70.682,29.804-120.425,99.801-120.425,181.203v84.578
      c-0.046,3.181-2.522,129.251-67.561,158.622c-7.409,3.347-11.481,11.412-9.768,19.36c1.711,7.949,8.74,13.626,16.871,13.626
      h164.88c3.38,18.594,12.172,35.892,25.619,49.903c17.86,18.608,41.479,28.856,66.502,28.856
      c25.025,0,48.644-10.248,66.502-28.856c13.449-14.012,22.241-31.311,25.619-49.903h164.88c8.131,0,15.159-5.676,16.872-13.626
      C581.586,511.664,577.516,503.6,570.107,500.254z M484.434,439.859c6.837,20.728,16.518,41.544,30.246,58.866H97.32
      c13.726-17.32,23.407-38.135,30.244-58.866H484.434z M306.001,34.515c18.945,0,34.963,12.73,39.975,30.082
      c-12.912-2.678-26.282-4.09-39.975-4.09s-27.063,1.411-39.975,4.09C271.039,47.246,287.057,34.515,306.001,34.515z
       M143.97,341.736v-84.685c0-89.343,72.686-162.029,162.031-162.029s162.031,72.686,162.031,162.029v84.826
      c0.023,2.596,0.427,29.879,7.303,63.465H136.663C143.543,371.724,143.949,344.393,143.97,341.736z M306.001,577.485
      c-26.341,0-49.33-18.992-56.709-44.246h113.416C355.329,558.493,332.344,577.485,306.001,577.485z"/>
          <path d="M306.001,119.235c-74.25,0-134.657,60.405-134.657,134.654c0,9.531,7.727,17.258,17.258,17.258
      c9.531,0,17.258-7.727,17.258-17.258c0-55.217,44.923-100.139,100.142-100.139c9.531,0,17.258-7.727,17.258-17.258
      C323.259,126.96,315.532,119.235,306.001,119.235z"/>
        </g>
      </g>
    </g>
    <g>
    </g>
    <g>
    </g>
    <g>
    </g>
    <g>
    </g>
    <g>
    </g>
    <g>
    </g>
    <g>
    </g>
    <g>
    </g>
    <g>
    </g>
    <g>
    </g>
    <g>
    </g>
    <g>
    </g>
    <g>
    </g>
    <g>
    </g>
    <g>
    </g>
  </svg>

)
export const auditIcon = (
  <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
    width="512.000000pt" height="512.000000pt" viewBox="0 0 512.000000 512.000000"
    preserveAspectRatio="xMidYMid meet" class="svg-inline--fa fa-trash fa-w-54">

    <g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
      fill="#000" stroke="none">
      <path d="M1950 5102 c-36 -9 -87 -30 -115 -46 -73 -42 -782 -754 -818 -822
-58 -110 -57 -100 -57 -935 l0 -766 -28 -69 c-134 -325 -117 -699 44 -1003
l45 -85 -61 -61 -60 -61 -66 4 c-120 8 -122 7 -470 -342 -349 -349 -351 -352
-342 -469 3 -35 12 -76 21 -92 8 -17 84 -98 169 -181 132 -130 159 -152 201
-164 58 -16 132 -8 184 19 21 10 107 89 190 173 167 168 176 186 128 233 -47
48 -64 38 -229 -124 -150 -148 -181 -169 -229 -158 -12 4 -82 66 -155 140
-124 125 -132 136 -132 172 0 38 10 49 302 342 l303 303 44 0 c45 0 47 -2 178
-133 129 -129 133 -134 133 -175 0 -39 -6 -49 -65 -112 -36 -38 -65 -76 -65
-84 0 -43 43 -86 86 -86 9 0 47 30 84 66 89 85 114 141 108 235 l-5 67 58 58
59 58 78 -42 c70 -37 204 -87 334 -123 45 -12 48 -16 68 -71 29 -80 111 -168
198 -210 l67 -33 1335 0 1335 0 65 31 c80 38 128 79 168 142 67 106 63 -14 60
1698 l-3 1549 -33 67 c-40 81 -129 168 -199 194 -60 21 -66 27 -87 87 -25 67
-113 159 -188 197 -34 16 -67 30 -75 30 -7 0 -13 6 -13 13 0 31 -62 137 -106
179 -48 45 -137 98 -167 98 -8 0 -23 24 -36 60 -25 69 -100 157 -167 196 -93
55 -74 54 -1079 53 -842 0 -936 -2 -995 -17z m1970 -153 c55 -25 95 -62 123
-114 l22 -40 3 -1513 c1 -1025 -1 -1526 -8 -1553 -15 -56 -68 -119 -125 -148
l-49 -26 -383 -3 -382 -3 20 58 c104 298 89 624 -42 902 -163 349 -491 601
-867 666 -111 20 -308 19 -413 -1 -250 -47 -436 -143 -617 -318 l-92 -89 2
616 c3 608 3 616 24 644 47 63 66 68 324 73 229 5 236 6 292 32 80 37 152 107
191 187 l32 65 5 236 c4 198 8 240 22 267 17 30 56 64 88 76 8 3 413 6 900 6
870 1 886 0 930 -20z m-2087 -291 c-2 -112 -5 -217 -8 -234 -7 -43 -56 -108
-107 -141 l-41 -28 -226 -5 c-124 -3 -228 -4 -231 -2 -4 3 602 612 610 612 3
0 4 -91 3 -202z m2446 -44 c22 -19 49 -54 60 -78 21 -42 21 -48 21 -1567 l0
-1524 -24 -51 c-13 -28 -39 -63 -58 -77 -77 -59 -54 -57 -717 -57 l-610 0 47
70 47 70 402 0 c260 0 421 4 455 11 106 23 199 87 257 177 64 100 61 20 61
1614 0 956 3 1448 10 1448 5 0 28 -16 49 -36z m312 -311 c63 -67 64 -73 67
-378 3 -269 4 -281 24 -302 30 -33 80 -31 107 3 20 25 21 39 21 230 0 151 3
204 12 204 25 0 104 -96 116 -141 10 -34 12 -393 10 -1565 l-3 -1521 -30 -49
c-19 -30 -49 -60 -79 -79 l-49 -30 -1299 -3 -1299 -2 -49 21 c-46 20 -120 87
-120 109 0 6 446 10 1238 12 l1237 3 58 24 c108 43 202 141 239 251 17 48 24
2272 8 2311 -11 25 -47 49 -74 49 -8 0 -24 -9 -37 -19 l-24 -19 -5 -1134 -5
-1133 -24 -48 c-30 -62 -96 -113 -164 -127 -35 -7 -345 -9 -961 -8 l-909 3 59
39 c32 21 78 54 101 72 l41 34 689 0 c750 0 742 -1 840 57 56 33 120 105 152
173 l26 55 3 1483 2 1482 28 -14 c15 -8 39 -27 53 -43z m-2356 -1282 c210 -44
367 -130 525 -290 146 -149 218 -275 272 -479 19 -74 23 -111 22 -247 -1 -139
-4 -173 -26 -256 -62 -231 -192 -425 -375 -563 -320 -240 -738 -279 -1091
-102 -644 324 -768 1184 -242 1679 138 130 307 218 490 257 117 25 306 26 425
1z m-1047 -1850 l79 -79 -43 -43 -44 -43 -80 79 -80 79 42 43 c23 24 43 43 44
43 2 0 38 -36 82 -79z"/>
      <path d="M1575 3948 c-19 -17 -53 -94 -126 -285 -104 -276 -109 -298 -66 -328
48 -33 104 -4 127 64 l13 41 102 0 102 0 13 -41 c30 -89 118 -107 149 -31 11
27 4 52 -85 288 -102 272 -125 314 -179 314 -13 0 -36 -10 -50 -22z m81 -332
c6 -24 4 -26 -30 -26 -41 0 -42 0 -19 66 l17 49 12 -31 c7 -17 16 -43 20 -58z"/>
      <path d="M1985 3945 c-25 -24 -25 -25 -25 -230 0 -228 5 -253 63 -313 119
-125 330 -98 410 51 20 38 22 60 25 232 4 210 -3 255 -43 273 -36 17 -48 15
-79 -9 l-26 -20 0 -189 c0 -104 -4 -200 -10 -214 -11 -30 -57 -56 -99 -56 -17
0 -42 11 -60 26 l-31 26 0 199 c0 199 0 200 -25 224 -13 14 -36 25 -50 25 -14
0 -37 -11 -50 -25z"/>
      <path d="M2588 3950 l-28 -21 0 -284 0 -284 28 -21 c25 -20 36 -22 142 -18 94
3 123 8 156 25 57 30 119 104 141 166 21 61 23 191 5 254 -20 68 -71 133 -132
167 -51 29 -63 31 -170 34 -106 4 -117 2 -142 -18z m263 -168 c64 -72 64 -202
0 -274 -23 -26 -37 -32 -84 -36 l-57 -4 0 177 0 177 57 -4 c47 -4 61 -10 84
-36z"/>
      <path d="M3166 3949 l-26 -20 0 -284 c0 -279 0 -285 22 -305 45 -41 111 -13
123 53 4 18 5 144 3 280 l-3 249 -28 24 c-34 29 -56 30 -91 3z"/>
      <path d="M3425 3945 c-30 -30 -32 -64 -4 -99 17 -22 28 -26 70 -26 l49 0 0
-229 0 -230 26 -20 c43 -34 86 -26 109 20 12 22 15 74 15 244 l0 215 45 0 c56
0 85 25 85 74 0 25 -7 40 -26 55 -24 19 -40 21 -186 21 -155 0 -159 -1 -183
-25z"/>
      <path d="M1895 2859 c-167 -24 -332 -106 -459 -228 -180 -173 -266 -374 -266
-622 0 -337 183 -629 484 -774 140 -67 196 -80 371 -80 177 0 254 18 395 90
201 104 348 276 419 491 203 609 -307 1215 -944 1123z m330 -169 c122 -38 202
-87 295 -180 130 -130 188 -247 210 -421 36 -291 -121 -581 -385 -714 -246
-124 -547 -93 -759 78 -126 101 -217 243 -252 394 -25 105 -15 284 20 388 83
241 283 417 536 471 90 19 245 11 335 -16z"/>
      <path d="M2231 2476 c-66 -24 -106 -70 -196 -230 -118 -209 -103 -196 -147
-138 -65 88 -189 127 -281 89 -113 -48 -174 -184 -133 -298 11 -32 248 -349
315 -422 69 -76 195 -96 282 -45 24 14 52 34 61 44 22 26 383 666 397 705 15
44 14 123 -2 162 -48 112 -184 174 -296 133z m134 -161 c46 -45 39 -64 -160
-415 -150 -265 -189 -327 -214 -339 -48 -23 -79 -2 -168 112 -196 254 -213
278 -213 308 0 42 39 79 83 79 38 0 60 -20 150 -137 28 -36 59 -66 73 -69 58
-15 67 -5 196 224 67 119 133 227 145 240 30 30 77 29 108 -3z"/>
    </g>
  </svg>

)


export const customerSupport = (
<svg version="1.0" xmlns="http://www.w3.org/2000/svg"
 width="50.000000pt" height="50.000000pt" viewBox="0 0 50.000000 50.000000"
 preserveAspectRatio="xMidYMid meet">

<g transform="translate(0.000000,50.000000) scale(0.100000,-0.100000)"
fill="#fff" stroke="none">
<path fill="#fff" d="M170 458 c-59 -30 -80 -70 -91 -170 -9 -73 -8 -83 10 -102 10 -12 28
-39 39 -62 28 -56 85 -104 122 -104 19 0 42 11 62 30 37 33 21 41 -19 10 -51
-40 -100 -15 -146 77 -14 27 -33 55 -41 62 -24 20 -19 34 9 27 23 -6 25 -4 25
33 0 38 1 39 47 50 25 7 59 19 74 26 25 14 31 13 63 -8 31 -20 35 -27 36 -66
0 -34 3 -42 15 -37 21 8 19 -17 -5 -56 -11 -18 -20 -36 -20 -40 0 -15 28 4 38
25 6 12 11 16 11 10 2 -38 -78 -70 -106 -42 -6 6 -23 9 -39 7 -23 -2 -29 -8
-29 -28 0 -20 6 -26 29 -28 16 -2 33 1 39 7 6 6 26 11 44 11 38 0 83 37 83 68
0 11 7 22 15 26 19 7 21 82 1 93 -7 4 -16 30 -18 57 -7 63 -34 97 -91 117 -26
8 -50 19 -54 22 -13 14 -60 7 -103 -15z m94 -7 c3 -4 23 -11 45 -15 66 -10
106 -87 83 -159 l-10 -32 -1 38 c-1 20 -5 37 -11 37 -5 0 -25 14 -44 31 l-36
31 -26 -21 c-14 -11 -53 -26 -85 -33 -58 -12 -59 -13 -59 -45 0 -18 -4 -33
-10 -33 -15 0 -12 97 4 128 8 15 23 35 34 45 31 29 105 46 116 28z m166 -221
c0 -16 -4 -30 -10 -30 -5 0 -10 14 -10 30 0 17 5 30 10 30 6 0 10 -13 10 -30z
m-150 -130 c0 -5 -9 -10 -20 -10 -11 0 -20 5 -20 10 0 6 9 10 20 10 11 0 20
-4 20 -10z"/>
</g>
</svg>
)

export const supportTicketIcon = (
  <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
 width="512.000000pt" height="512.000000pt" viewBox="0 0 512.000000 512.000000"
 preserveAspectRatio="xMidYMid meet">

<g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
fill="#fff" stroke="none">
<path d="M2323 5110 c-598 -58 -1146 -316 -1571 -740 -404 -404 -648 -900
-734 -1495 -10 -70 -13 -382 -13 -1415 l0 -1325 24 -38 c13 -21 42 -50 64 -65
l41 -27 1325 0 c1034 0 1346 3 1416 13 594 86 1087 328 1493 732 397 397 640
882 729 1460 24 161 24 539 0 700 -89 577 -330 1059 -727 1458 -394 395 -885
641 -1450 727 -125 19 -468 27 -597 15z m562 -415 c462 -72 869 -279 1200
-610 334 -334 538 -738 612 -1210 12 -78 17 -170 17 -315 0 -145 -5 -237 -17
-315 -74 -472 -278 -876 -612 -1210 -329 -329 -717 -527 -1195 -612 -84 -14
-231 -17 -1293 -20 l-1198 -4 4 1198 c3 1062 6 1209 20 1293 85 478 283 866
612 1195 368 368 805 572 1350 629 81 8 403 -4 500 -19z"/>
<path d="M2372 3709 c-282 -43 -500 -219 -527 -425 -17 -124 73 -225 200 -226
67 -1 90 15 160 108 93 124 178 161 345 152 188 -11 295 -112 275 -261 -14
-108 -68 -177 -250 -323 -149 -119 -216 -211 -262 -361 -13 -44 -18 -92 -18
-198 0 -157 12 -196 71 -224 41 -20 136 -26 200 -12 65 15 114 63 114 114 1
18 5 76 9 129 12 131 45 194 155 292 225 200 290 274 360 413 178 349 -18 704
-445 808 -79 19 -302 27 -387 14z"/>
<path d="M2413 1676 c-131 -42 -201 -146 -190 -282 17 -221 275 -320 438 -167
54 50 89 127 89 193 0 95 -76 208 -164 245 -51 22 -126 26 -173 11z"/>
</g>
</svg>
)

export const frntentSiteList = (
  <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
 width="32px" height="32px" viewBox="0 0 512.000000 512.000000"
 preserveAspectRatio="xMidYMid meet">

<g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
fill="#000000" stroke="none">
<path d="M405 4487 c-138 -36 -257 -125 -334 -251 -61 -98 -85 -260 -57 -380
70 -298 380 -478 671 -388 232 71 394 310 372 547 -20 208 -158 387 -354 457
-72 26 -226 33 -298 15z"/>
<path d="M1894 4320 c-287 -43 -404 -391 -199 -595 25 -26 72 -59 103 -73 l57
-27 1500 0 1500 0 57 27 c63 29 139 101 167 157 93 186 7 415 -187 493 l-57
23 -1445 1 c-795 1 -1468 -2 -1496 -6z"/>
<path d="M408 3074 c-189 -45 -348 -206 -393 -396 -66 -279 115 -566 397 -633
295 -69 593 134 640 438 56 363 -286 678 -644 591z"/>
<path d="M1840 2897 c-97 -32 -174 -101 -219 -197 -22 -46 -26 -69 -26 -140 0
-94 24 -162 81 -229 37 -44 117 -94 174 -110 34 -9 396 -11 1525 -9 l1480 3
56 26 c79 37 133 90 171 167 29 59 33 76 33 152 0 76 -4 93 -33 152 -38 77
-92 130 -171 167 l-56 26 -1490 2 c-1099 1 -1499 -2 -1525 -10z"/>
<path d="M443 1670 c-268 -48 -458 -288 -440 -555 15 -214 150 -392 352 -467
86 -32 264 -32 348 -1 299 111 438 445 303 727 -40 82 -123 176 -201 225 -95
60 -250 90 -362 71z"/>
<path d="M1857 1489 c-141 -33 -253 -166 -264 -313 -13 -152 79 -299 223 -357
l59 -24 1480 0 1480 0 57 23 c194 78 280 307 187 493 -28 56 -104 128 -167
157 l-57 27 -1480 2 c-843 0 -1496 -3 -1518 -8z"/>
</g>
</svg>
)
export const frontendMarketingAssets = (
  <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
 width="32px" height="32px" viewBox="0 0 512.000000 512.000000"
 preserveAspectRatio="xMidYMid meet">

<g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
fill="#000000" stroke="none">
<path d="M230 4536 c-93 -25 -173 -95 -206 -182 -18 -47 -19 -106 -19 -1794
l0 -1745 23 -47 c32 -65 94 -125 160 -156 l57 -27 1925 -3 c1059 -1 1939 0
1957 3 72 13 73 15 533 935 278 556 442 895 449 928 6 29 11 73 11 96 0 52
-32 159 -60 201 -39 58 -115 126 -174 155 -79 39 -163 49 -393 50 l-182 0 -3
388 c-3 383 -3 387 -26 432 -33 63 -96 125 -156 152 l-51 23 -975 3 -975 3
-181 252 c-129 179 -194 261 -226 284 -86 62 -94 63 -803 62 -472 0 -652 -4
-685 -13z m1485 -528 c94 -130 183 -251 200 -268 16 -17 54 -43 85 -58 l55
-27 978 -3 977 -2 0 -350 0 -350 -1347 0 c-812 0 -1375 -4 -1415 -10 -135 -20
-238 -80 -306 -180 -17 -25 -162 -326 -322 -670 -161 -343 -298 -636 -305
-650 -12 -21 -14 185 -15 1393 l0 1417 623 -2 622 -3 170 -237z m3011 -1369
c51 -13 94 -57 94 -97 0 -16 -173 -375 -407 -844 l-407 -818 -1808 0 c-994 0
-1808 2 -1808 4 0 17 796 1705 812 1723 11 12 36 26 57 31 54 15 3409 15 3467
1z"/>
</g>
</svg>

)

export const frontendProposlTeam = (
  <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
 width="32px" height="32px" viewBox="0 0 512.000000 512.000000"
 preserveAspectRatio="xMidYMid meet">

<g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
fill="#000000" stroke="none">
<path d="M2414 4719 c-89 -15 -206 -57 -294 -106 -113 -63 -266 -215 -327
-323 -201 -357 -144 -788 143 -1074 170 -171 382 -258 624 -258 242 0 454 87
624 258 287 286 344 717 143 1074 -61 108 -214 260 -327 323 -178 99 -393 138
-586 106z"/>
<path d="M832 3880 c-197 -52 -367 -201 -445 -390 -37 -90 -48 -150 -48 -256
0 -107 19 -192 66 -289 82 -170 248 -304 435 -351 85 -22 235 -21 320 1 148
38 304 148 389 274 135 200 148 465 34 684 -51 98 -172 219 -270 270 -146 76
-326 97 -481 57z"/>
<path d="M3952 3880 c-169 -45 -336 -176 -415 -327 -91 -175 -103 -380 -31
-561 76 -189 254 -345 454 -397 85 -22 235 -23 320 -1 236 60 424 248 485 485
19 73 22 222 6 296 -53 243 -247 444 -487 505 -100 26 -233 26 -332 0z"/>
<path d="M1955 2755 c-152 -34 -252 -90 -366 -204 -95 -95 -146 -176 -188
-299 l-26 -77 -3 -840 c-2 -826 -2 -841 18 -873 11 -18 34 -41 52 -52 32 -20
53 -20 1118 -20 1065 0 1086 0 1118 20 18 11 41 34 52 52 20 32 20 47 18 873
l-3 840 -26 77 c-42 123 -93 204 -188 299 -95 95 -176 146 -299 188 l-77 26
-570 2 c-451 1 -583 -1 -630 -12z"/>
<path d="M642 2379 c-287 -37 -523 -241 -614 -529 -23 -75 -23 -77 -23 -595
l0 -520 23 -57 c47 -117 144 -213 260 -260 55 -22 67 -23 434 -26 l377 -3 -12
33 c-15 44 -23 1572 -8 1708 6 52 18 122 27 155 9 33 19 70 21 83 l5 22 -213
-1 c-118 -1 -243 -5 -277 -10z"/>
<path d="M3993 2368 c2 -13 12 -50 21 -83 9 -33 21 -103 27 -155 15 -136 7
-1664 -8 -1708 l-12 -33 377 3 c367 3 379 4 434 26 116 47 213 143 260 260
l23 57 0 520 c0 519 0 520 -24 595 -75 240 -244 418 -471 495 -101 34 -203 45
-433 45 l-199 0 5 -22z"/>
</g>
</svg>
)
export const frontendProposalSection = (
  <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
 width="32px" height="32px" viewBox="0 0 512.000000 512.000000"
 preserveAspectRatio="xMidYMid meet">

<g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
fill="#000000" stroke="none">
<path d="M245 5111 c-90 -22 -172 -90 -215 -176 l-25 -50 0 -2325 0 -2325 27
-51 c40 -76 70 -107 140 -145 l63 -34 935 0 935 0 53 24 c68 31 144 113 169
183 17 51 18 148 18 2348 0 2200 -1 2297 -18 2348 -25 70 -101 152 -169 183
l-53 24 -915 2 c-503 0 -928 -2 -945 -6z m1819 -215 c18 -8 41 -24 50 -37 15
-22 16 -211 16 -2299 0 -2088 -1 -2277 -16 -2299 -37 -52 -18 -51 -945 -51
-955 0 -911 -3 -943 65 -14 30 -16 255 -16 2285 0 2026 2 2255 16 2285 32 68
-14 65 942 65 715 0 868 -2 896 -14z"/>
<path d="M3005 5106 c-65 -21 -99 -42 -142 -89 -51 -55 -67 -86 -82 -155 -15
-73 -15 -1330 0 -1404 21 -103 100 -198 195 -234 47 -18 88 -19 979 -19 l930
0 51 27 c76 40 107 70 145 140 l34 63 0 725 0 725 -34 63 c-38 70 -69 100
-145 140 l-51 27 -920 2 c-724 2 -929 -1 -960 -11z m1840 -212 c67 -31 65 -11
65 -734 0 -573 -2 -656 -16 -685 -32 -68 12 -65 -943 -65 -927 0 -908 -1 -945
51 -14 21 -16 91 -16 699 0 608 2 678 16 699 36 51 16 50 942 51 767 0 868 -2
897 -16z"/>
<path d="M2817 2750 c-38 -30 -52 -80 -33 -123 30 -72 -48 -68 1175 -65 l1099
3 26 24 c51 48 40 142 -20 170 -18 8 -335 11 -1123 11 -1086 0 -1099 0 -1124
-20z"/>
<path d="M2817 2110 c-38 -30 -52 -80 -33 -123 30 -72 -48 -68 1175 -65 l1099
3 26 24 c51 48 40 142 -20 170 -18 8 -335 11 -1123 11 -1086 0 -1099 0 -1124
-20z"/>
<path d="M2817 1470 c-38 -30 -52 -80 -33 -123 30 -72 -48 -68 1175 -65 l1099
3 26 24 c51 48 40 142 -20 170 -18 8 -335 11 -1123 11 -1086 0 -1099 0 -1124
-20z"/>
<path d="M2817 830 c-38 -30 -52 -80 -33 -123 30 -72 -48 -68 1175 -65 l1099
3 26 24 c51 48 40 142 -20 170 -18 8 -335 11 -1123 11 -1086 0 -1099 0 -1124
-20z"/>
<path d="M2817 190 c-38 -30 -52 -80 -33 -123 30 -72 -48 -68 1175 -65 l1099
3 26 24 c51 48 40 142 -20 170 -18 8 -335 11 -1123 11 -1086 0 -1099 0 -1124
-20z"/>
</g>
</svg>

)

export const paypalIcon = (
  <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
 width="860.000000pt" height="282.000000pt" viewBox="0 0 860.000000 282.000000"
 preserveAspectRatio="xMidYMid meet">

<g transform="translate(0.000000,282.000000) scale(0.100000,-0.100000)"
fill="#745CA7" stroke="none">
<path d="M708 2383 c-16 -10 -34 -31 -42 -48 -19 -42 -346 -1466 -346 -1509 0
-58 21 -66 188 -66 153 0 185 8 214 52 8 13 34 106 58 208 23 102 49 197 57
212 27 55 52 62 232 70 103 4 203 14 262 27 314 66 523 234 584 471 13 49 16
93 13 176 -4 101 -7 118 -36 177 -54 110 -145 174 -307 219 -74 20 -107 22
-465 25 -353 3 -387 2 -412 -14z m676 -318 c81 -41 99 -74 93 -176 -6 -125
-64 -199 -196 -250 -43 -17 -85 -23 -179 -27 -184 -7 -187 -2 -131 235 46 200
49 207 91 234 29 18 46 20 148 17 105 -3 120 -6 174 -33z"/>
<path d="M5062 2377 c-12 -7 -29 -22 -37 -34 -8 -12 -51 -178 -95 -370 -44
-191 -87 -375 -95 -408 -7 -33 -32 -141 -55 -240 -23 -99 -57 -246 -76 -328
-56 -242 -59 -237 150 -237 162 0 194 9 220 58 7 15 33 110 55 212 23 102 49
195 57 207 28 44 53 51 209 58 348 16 554 87 715 245 114 112 170 245 170 405
0 220 -109 354 -338 417 -71 20 -107 22 -467 25 -306 2 -395 0 -413 -10z m642
-297 c21 -6 57 -29 80 -50 l41 -39 0 -94 c0 -87 -2 -97 -30 -143 -64 -102
-174 -148 -359 -149 -118 0 -119 0 -139 27 l-21 28 39 176 c22 97 47 189 57
206 30 48 68 59 188 54 58 -3 122 -10 144 -16z"/>
<path d="M7952 2367 c-12 -7 -29 -23 -38 -35 -11 -16 -258 -1100 -339 -1489
-5 -27 -2 -37 19 -58 24 -24 29 -25 157 -25 114 0 136 3 164 20 42 26 47 39
94 255 229 1031 281 1270 281 1286 0 11 -9 28 -20 39 -18 18 -33 20 -157 20
-91 -1 -146 -5 -161 -13z"/>
<path d="M2450 2024 c-236 -29 -307 -52 -333 -107 -23 -48 -52 -189 -44 -211
8 -21 19 -20 162 20 88 24 118 27 250 28 167 1 227 -13 265 -61 26 -34 28
-108 3 -121 -10 -5 -63 -12 -118 -16 -241 -16 -462 -64 -589 -128 -83 -42
-168 -125 -200 -197 -97 -216 -21 -424 179 -492 24 -9 81 -14 145 -13 131 0
233 32 345 105 42 27 79 49 83 49 4 0 6 -18 4 -39 -3 -30 1 -44 17 -60 19 -19
32 -21 154 -21 113 0 137 3 164 20 17 10 36 27 41 37 16 30 191 793 198 864 8
81 -12 158 -56 211 -38 46 -132 93 -225 114 -68 15 -370 27 -445 18z m260
-700 c0 -31 -52 -220 -66 -242 -9 -13 -50 -40 -92 -60 -70 -34 -84 -37 -162
-37 -131 1 -170 29 -170 122 0 129 84 184 335 218 129 18 155 18 155 -1z"/>
<path d="M6875 2023 c-94 -5 -240 -25 -303 -40 -83 -21 -104 -46 -133 -166
-12 -52 -19 -102 -15 -112 7 -20 7 -20 151 21 90 25 116 28 260 28 106 1 172
-3 196 -12 50 -18 87 -59 94 -108 10 -59 -6 -70 -112 -79 -522 -41 -756 -146
-832 -373 -30 -88 -29 -210 3 -277 61 -133 213 -198 402 -175 118 15 181 41
343 142 l33 21 -6 -39 c-6 -32 -3 -45 14 -67 21 -27 22 -27 152 -27 191 0 197
5 242 200 14 58 53 224 87 370 67 285 83 414 59 486 -30 91 -142 164 -288 189
-81 13 -262 23 -347 18z m185 -702 c0 -27 -39 -190 -55 -227 -20 -48 -144
-107 -236 -112 -90 -5 -132 3 -168 34 -79 66 -31 211 86 262 64 27 222 56 331
60 35 2 42 -1 42 -17z"/>
<path d="M3328 2000 c-40 -25 -40 -36 28 -421 117 -678 136 -793 137 -864 l2
-70 -107 -185 c-133 -232 -133 -232 -119 -259 11 -20 19 -21 167 -21 167 0
202 9 250 62 12 12 174 279 359 593 186 314 403 680 483 815 80 134 153 263
164 286 32 73 30 74 -171 74 l-178 0 -44 -30 c-48 -31 -41 -20 -313 -485 -73
-126 -141 -231 -149 -233 -20 -4 -18 -15 -78 353 -52 315 -53 320 -85 355
l-32 35 -149 3 c-90 1 -155 -2 -165 -8z"/>
</g>
</svg>


)
export const mediaUpload = (
  <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="upload" class="svg-inline--fa fa-upload fa-w-16 " role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path fill="currentColor" d="M296 384h-80c-13.3 0-24-10.7-24-24V192h-87.7c-17.8 0-26.7-21.5-14.1-34.1L242.3 5.7c7.5-7.5 19.8-7.5 27.3 0l152.2 152.2c12.6 12.6 3.7 34.1-14.1 34.1H320v168c0 13.3-10.7 24-24 24zm216-8v112c0 13.3-10.7 24-24 24H24c-13.3 0-24-10.7-24-24V376c0-13.3 10.7-24 24-24h136v8c0 30.9 25.1 56 56 56h80c30.9 0 56-25.1 56-56v-8h136c13.3 0 24 10.7 24 24zm-124 88c0-11-9-20-20-20s-20 9-20 20 9 20 20 20 20-9 20-20zm64 0c0-11-9-20-20-20s-20 9-20 20 9 20 20 20 20-9 20-20z"></path></svg>
)