import React, { useState } from 'react';
import { withRouter } from 'react-router-dom';
import { Link } from 'react-router-dom';
import './CommonNotificationActivityStyle.scss';
import { commonJsFuncModule as commonJsObj } from '../../../utils/commonFunc';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle, faQuestionCircle } from '@fortawesome/free-solid-svg-icons';

import { Modal } from 'react-bootstrap';


const CommonNotificationActivityView = (props) => {
    const randomNumber = commonJsObj.generateRandomString(15);
    const [isOpen, setIsOpen] = useState(false)


    const siteViewToggelHandler = (v) => {
        setIsOpen(v)
    }

    return (
        <>



            {
                (props.globalState && props.globalState.state.notificationInfo && props.globalState.state.notificationInfo.length > 0 || props.globalState.state.sitePaymentInfo && props.globalState.state.sitePaymentInfo.length > 0) ? <div className='mt-5'>

                    {props.globalState.state.sitePaymentInfo != undefined && props.globalState.state.sitePaymentInfo.length == 1 &&
                        props.globalState.state.sitePaymentInfo.map((_item, _index) => (
                            <div class="alert alert-warning" key={_index}>
                                <div className='notficicatinHldr hauto'>
                                    <FontAwesomeIcon icon={faQuestionCircle} />

                                    {(_item.noOfOrders == 0) ? `Procedure to add your site ${_item.name} is partially completed and pending for payment. Please complete the payment process to start the WordPromise Security Auditing activities.` : `Site ${_item.name} subscription tenure has expired. <Link to={/checkout/${randomNumber}-${_item.id}}>Click Here</Link> to complete the renewal.`}




                                </div>

                            </div>
                        ))
                    }
                    {props.globalState.state.sitePaymentInfo != undefined && props.globalState.state.sitePaymentInfo.length > 1 &&
                        <div class="alert alert-warning">
                            <div className='notficicatinHldr hauto'>
                                <FontAwesomeIcon icon={faQuestionCircle} />
                                {props.globalState.state.sitePaymentInfo.length} site(s) subscription tenure has expired.<span className="viewSiteListLink" onClick={() => siteViewToggelHandler(!isOpen)}>View Site List</span>


                            </div>

                        </div>
                    }
                    {props.globalState.state.sitePaymentInfo != undefined && props.globalState.state.sitePaymentInfo.length > 1 && isOpen &&
                        props.globalState.state.sitePaymentInfo.map((_item, _index) => (
                            <div class="alert alert-warning" key={_index}>
                                <div className='notficicatinHldr hauto'>
                                    <FontAwesomeIcon icon={faQuestionCircle} />
                                    {(_item.noOfOrders == 0) ? `Procedure to add your site ${_item.name} is partially completed and pending for payment. Please complete the payment process to start the WordPromise Security Auditing activities.` : `Site ${_item.name} subscription tenure has expired. <Link to={/checkout/${randomNumber}-${_item.id}}>Click Here</Link> to complete the renewal.`}





                                </div>

                            </div>
                        ))
                    }
                    {
                        props.globalState && props.globalState.state.notificationInfo.length > 0 &&
                        props.globalState.state.notificationInfo.map((_item, _index) => (
                            <div class="alert alert-info" key={_index}>
                                <div className='notficicatinHldr hauto'>
                                    {
                                        (_item.type !== 'ST') ? <> <FontAwesomeIcon icon={faInfoCircle} /><span className="mainText" dangerouslySetInnerHTML={{ __html: _item.content }} /></> : <><FontAwesomeIcon icon={faInfoCircle} /><span className="mainText" dangerouslySetInnerHTML={{ __html: _item.content }} /></>
                                    }

                                    {
                                        (_item.type === 'PC') ? <span className='clickHereAnchire' onClick={(e) => props.closeNotification(_item.id, 'PC')}>Click Here</span> : ""
                                    }
                                    {/* {
                                        (_item.type === 'ST') ? <span className='clickHereAnchire' onClick={(e) => props.closeNotification(_item.id, 'ST')}>Click Here</span> : ""
                                    } */}
                                    {
                                        (_item.type === 'PH' && _item.is_login_required == 1) ? props.displayNotificationModal(_item.id) : ""
                                    }
                                    {
                                        (_item.type === 'PHDA' && _item.is_login_required == 1) ? props.displayDeActivatedNotificationModal(_item.id) : ""
                                    }
                                    {
                                        
                                        (_item.type === 'UDA') ? props.userDeactivatedNotificatinModal(_item.id) : ""

                                    }
                                    <button type="button" onClick={(e) => props.closeNotification(_item.id)} className="close" >
                                        <span aria-hidden="true">&times;</span>
                                    </button>
                                </div>

                            </div>
                        ))

                    }




                </div> : ""
            }



            <Modal show={props.pHModelStatus} className="notifyModal">


                <Modal.Body>
                    <p className='text-center iconHld'><FontAwesomeIcon icon={faInfoCircle} /></p>
                    <p>You have been marked as privileged agency partner at WordPromise. Please logout and login again to get your `Partner Hub` in the main menu.</p>

                    <Link to="/logout" onClick={(e) => props.logoutHandler(e, props.phNotifyId)} className="ml-auto btn btn-outline-success mr-4 mb-2 text-center">Logout</Link>

                </Modal.Body>


            </Modal>

            <Modal show={props.pHDAModelStatus} className="notifyModal">


                <Modal.Body>
                    <p className='text-center iconHld'><FontAwesomeIcon icon={faInfoCircle} /></p>
                    <p>Partner Hub Section at your login has been deactivated, contact administrator for any clarification.</p>

                    <Link to="/logout" onClick={(e) => props.logoutHandler(e, props.phDANotifyId)} className="ml-auto btn btn-outline-success mr-4 mb-2 text-center">Logout</Link>

                </Modal.Body>


            </Modal>
            <Modal show={props.UDAModelStatus} className="notifyModal">


                <Modal.Body>
                    <p className='text-center iconHld'><FontAwesomeIcon icon={faInfoCircle} /></p>
                    <p>Oops! Your login session has been expired. Please login again.</p>

                    <Link to="/Login" onClick={(e) => props.logoutHandler(e, props.UDAANotifyId, 'login-disable')} className="ml-auto btn btn-outline-success mr-4 mb-2 text-center">Contact Us</Link>

                </Modal.Body>


            </Modal>


        </>
    );
};


export default withRouter(CommonNotificationActivityView);
