import React from 'react';
import './custLoder.scss'
const CustLoader = () => {
    return (  
        <>
           
           <div className="lds-roller" >
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
            </div>
        </>
    );
}
export default CustLoader