import jwt from 'jsonwebtoken';

let accessCodeVerifyDecode = (data) => {

    let base64DecriptedValue = new Buffer.from(data, 'base64').toString('ascii');

    try {
        return jwt.verify(base64DecriptedValue, '68V0zWFrS72GbpPreidkQFLfj4v9m3TiDXc8OB0gcM', (err, decoded) => {
            if (err) {
                return {
                    status: 401,
                    message: 'Access Code Expired! Please re-generate your code and try again ',
                    data: ""
                }
            }
            else {
                return {
                    status: 200,
                    message: 'Success',
                    data: decoded
                }
            }
        })
    }
    catch (e) {
        return {
            status: 501,
            message: 'Internal Server Error!',
            data: ""
        }
    }




};

export const jwtVerify = {
    accessCodeVerifyDecode,

};