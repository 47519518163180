import React, { lazy } from 'react'
import { Route, Redirect } from 'react-router-dom';
import { commonJsFuncModule as commonJsObj } from '../utils/commonFunc';

import { AuthGuard as Auth } from '../guards/AuthGuard'

import MainLayout from '../components/Layout/MainLayout';
import LoginLayout from '../components/Layout/LoginLayout';
import OtherLayout from '../components/Layout/OtherLayout';
import GuestAccessLayout from '../components/Layout/GuestAccessLayout';
import GuestLayout from '../components/Layout/GuestLayout';


const SignInComponent = lazy(() => import('../components/SignIn/SignInComponent'));
const SignUpComponent = lazy(() => import('../components/SignUp/SignUpComponent'));
const HelpComponent = lazy(() => import('../components/Help/HelpComponent'));

const DashboardComponent = lazy(() => import('../components/Dashboard/DashboardComponent'));
const ProfileManageComponent = lazy(() => import('../components/Profile/ProfileComponent'));
const SetPasswordComponent = lazy(() => import('../components/SetPassword/SetPasswordComponent'));
const ChangePasswordComponent = lazy(() => import('../components/ChangePassword/ChangePasswordComponent'));

const ForgetPasswordComponent = lazy(() => import('../components/ForgotPassword/ForgotPasswordComponent'));
const ResetPasswordComponent = lazy(() => import('../components/ResetPassword/ResetPasswordComponent'));

const SiteListComponent = lazy(() => import('../components/Sites/List/ListComponent'));
const SiteDiscontinuedListComponent = lazy(() => import('../components/Sites/Discontinued/ListComponent'));
const SiteManageComponent = lazy(() => import('../components/Sites/Manage/ManageComponent'));
const SiteInstallComponent = lazy(() => import('../components/Sites/Install/ListComponent'));
const SiteDashboardComponent = lazy(() => import('../components/Sites/Dashboard/ListComponent'));
const SiteFTPComponent = lazy(() => import('../components/Sites/FTP/ManageComponent'));
const SiteLoginInfoComponent = lazy(() => import('../components/Sites/LoginInfo/ManageComponent'));
const SiteNotificationListComponent = lazy(() => import('../components/Sites/Notification/List/ListComponent'));
const SiteAuditReportListComponent = lazy(() => import('../components/Sites/AuditReport/ListComponent'));
const SiteAuditHtmlReportListComponent = lazy(()=> import('../components/Sites/AuditHtmlReport/ListComponent'));

const TicketListComponent = lazy(() => import('../components/Ticket/List/ListComponent'));
const TicketManageComponent = lazy(() => import('../components/Ticket/Manage/ManageComponent'));
const TicketBrowseComponent = lazy(() => import('../components/Ticket/Browse/ManageComponent'));

const ProposalSectionListComponent = lazy(() => import('../components/ProposalBuilder/ProposalSection/List/ListComponent'));
const ProposalSectionManageComponent = lazy(() => import('../components/ProposalBuilder/ProposalSection/Manage/ManageComponent'));
const ProposalTeamListComponent = lazy(() => import('../components/ProposalBuilder/ProposalTeam/List/ListComponent'));
const ProposalTeamManageComponent = lazy(() => import('../components/ProposalBuilder/ProposalTeam/Manage/ManageComponent'));
const ProposalListComponent = lazy(() => import('../components/ProposalBuilder/Proposal/List/ListComponent'));
const ProposalManageComponent = lazy(() => import('../components/ProposalBuilder/Proposal/Manage/ManageComponent'));

const MediaComponent = lazy(() => import('../components/ProposalBuilder/Media/ManageComponent'));


const ProposalAcceptComponent = lazy(() => import('../components/ProposalBuilder/Proposal/Accept/AcceptComponent'));
const ProposalDeclineComponent = lazy(() => import('../components/ProposalBuilder/Proposal/Decline/DeclineComponent'));

const CheckoutComponent = lazy(() => import('../components/Checkout/Manage/ManageComponent'));
const PaymentManageComponent = lazy(() => import('../components/Payment/Manage/ManageComponent'));

const OfferListComponent = lazy(() => import('../components/Offer/List/ListComponent'));
const ThankYouComponent = lazy(() => import('../components/ThankYou/ThankYouComponent'));

const OrderListComponent = lazy(() => import('../components/Order/List/ListComponent'))
const OrderDetailComponent = lazy(() => import('../components/Order/Detail/DetailComponent'))
const OrderSubscriptionListComponent = lazy(() => import('../components/Order/Subscription/ListComponent'))


const NotificationListComponent = lazy(() => import('../components/Notification/List/ListComponent'))
const BecomeAnAgencyPartnerListComponent = lazy(() => import('../components/BecomeAnAgencyPartner/List/ListComponent'))
const MarketingAssetListComponent = lazy(() => import('../components/MarketingAsset/List/ListComponent'))


const GuestVerifyAccessCodeComponent = lazy(()=>import('../components/GuestAccess/GuestVerifyAccessCode/GuestVerifyAccessCodeComponent'));
const GuestSiteDashboardComponent = lazy(()=> import('../components/GuestAccess/Site/Dashboard/ListComponent'));
const GuestSiteAuditReportListComponent = lazy(() => import('../components/GuestAccess/Site/AuditReport/ListComponent'));
const GuestSiteAuditHtmlReportListComponent = lazy(()=> import('../components/GuestAccess/Site/AuditHtmlReport/ListComponent'));
const GuestSiteNotificationListComponent = lazy(() => import('../components/GuestAccess/Site/Notification/List/ListComponent'));

const GenerateMailOrderPDFComponent = lazy(()=>import('../components/GenerateMailOrderPDF/GenerateMailOrderPDFComponent'));

const ErrorComponent = lazy(() => import('../components/Error/ErrorComponent'));


const Main = ({ component: Component, ...rest }) => {
	// console.log(rest.guard, 'rest.guard')
	if (rest.guard) {
		if (commonJsObj.isGuardValid(rest.guard)) {
			return (
				<Route {...rest} render={matchProps => (
					<MainLayout >
						<Component {...matchProps} />
					</MainLayout>
				)} />
			)

		}
		else {
			return (
				<Route {...rest} render={matchProps => (
					<Redirect to={{ pathname: '/', state: { from: matchProps.location } }} />
				)} />
			)
		}
	}
	return (
		<Route {...rest} render={matchProps => (
			<MainLayout>
				<Component {...matchProps} />
			</MainLayout>
		)} />
	);

};
const Login = ({ component: Component, ...rest }) => {
	return (
		<Route {...rest} render={matchProps => (
			localStorage.getItem('wpaFrontendUser') ? <Redirect to={{ pathname: '/dashboard', state: { from: matchProps.location } }} /> :
				<LoginLayout>
					<Component {...matchProps} />
				</LoginLayout>
		)} />
	);
};

const Other = ({ component: Component, ...rest }) => {
	return (
		<Route {...rest} render={matchProps => (
			<OtherLayout>
				<Component {...matchProps} />
			</OtherLayout>
		)} />
	);
};
const GuestAccess = ({ component: Component, ...rest }) => {
	return (
		<Route {...rest} render={matchProps => (
			<GuestAccessLayout>
				<Component {...matchProps} />
			</GuestAccessLayout>
		)} />
	);
};
const Guest = ({ component: Component, ...rest }) => {
	return (
		<Route {...rest} render={matchProps => (
			<GuestLayout>
				<Component {...matchProps} />
			</GuestLayout>
		)} />
	);
};
const routeArr = [
	{ path: '', component: SignInComponent, layout: 'Login' },
	{ path: 'login', component: SignInComponent, layout: 'Login' },
	{ path: 'sign-up', component: SignUpComponent, layout: 'Login' },
	{ path: 'help', component: HelpComponent, layout: 'Login' },
	{ path: 'set-password/:uniqueId', component: SetPasswordComponent, layout: 'Login' },
	{ path: 'forgot-password', component: ForgetPasswordComponent, layout: 'Login' },
	{ path: 'reset-password/:uniqueId', component: ResetPasswordComponent, layout: 'Login' },


	{ path: 'dashboard', component: DashboardComponent, layout: 'Main', guards: [Auth] },
	{ path: 'change-password', component: ChangePasswordComponent, layout: 'Main', guards: [Auth] },
	{ path: 'my-profile', component: ProfileManageComponent, layout: 'Main', guards: [Auth] },

	{ path: 'sites', component: SiteListComponent, layout: 'Main', guards: [Auth] },
	{ path: 'site/discontinued', component: SiteDiscontinuedListComponent, layout: 'Main', guards: [Auth] },
	{ path: 'site/manage/:id?', component: SiteManageComponent, layout: 'Main', guards: [Auth] },
	{ path: 'site/install/:id', component: SiteInstallComponent, layout: 'Main', guards: [Auth] },
	{ path: 'site/dashboard/:id', component: SiteDashboardComponent, layout: 'Main', guards: [Auth] },
	{ path: 'site/ftp/:id', component: SiteFTPComponent, layout: 'Main', guards: [Auth] },
	{ path: 'site/login/:id', component: SiteLoginInfoComponent, layout: 'Main', guards: [Auth] },
	{ path: 'site/notification/:id', component: SiteNotificationListComponent, layout: 'Main', guards: [Auth] },
	{ path: 'site/report/:id', component: SiteAuditReportListComponent, layout: 'Main', guards: [Auth] },
	{ path: 'site/report/view/:id', component: SiteAuditHtmlReportListComponent, layout: 'Main', guards: [Auth] },

	{ path: 'site/report/:id/:archive', component: SiteAuditReportListComponent, layout: 'Main', guards: [Auth] },

	{ path: 'ticket', component: TicketListComponent, layout: 'Main', guards: [Auth] },
	{ path: 'ticket/list/:id?', component: TicketListComponent, layout: 'Main', guards: [Auth] },
	{ path: 'ticket/manage', component: TicketManageComponent, layout: 'Main', guards: [Auth] },
	{ path: 'ticket/reply/:id?', component: TicketBrowseComponent, layout: 'Main', guards: [Auth] },


	{ path: 'proposal-section', component: ProposalSectionListComponent, layout: 'Main', guards: [Auth] },
	{ path: 'proposal-section/manage/:id?', component: ProposalSectionManageComponent, layout: 'Main', guards: [Auth] },

	{ path: 'proposal-team', component: ProposalTeamListComponent, layout: 'Main', guards: [Auth] },
	{ path: 'proposal-team/manage/:id?', component: ProposalTeamManageComponent, layout: 'Main', guards: [Auth] },

	{ path: 'proposal', component: ProposalListComponent, layout: 'Main', guards: [Auth] },
	{ path: 'proposal/manage/:id?', component: ProposalManageComponent, layout: 'Main', guards: [Auth] },
	{ path: 'proposal/accept/:proposalNumber', component: ProposalAcceptComponent, layout: 'Other', guards: [Auth] },
	{ path: 'proposal/decline/:proposalNumber', component: ProposalDeclineComponent, layout: 'Other', guards: [Auth] },

	{ path: 'media-library', component: MediaComponent, layout: 'Main', guards: [Auth] },	

	{ path: 'offers', component: OfferListComponent, layout: 'Main', guards: [Auth] },
	{ path: 'checkout/:checkoutId', component: CheckoutComponent, layout: 'Main', guards: [Auth] },
	{ path: 'checkout/payment/:checkoutId', component: PaymentManageComponent, layout: 'Main', guards: [Auth] },

	{ path: 'payment/success/:id?', component: ThankYouComponent, layout: 'Main', guards: [Auth] },
	{ path: 'become-an-agency-partner', component: BecomeAnAgencyPartnerListComponent, layout: 'Main', guards: [Auth] },
	{ path: 'marketing-asset', component: MarketingAssetListComponent, layout: 'Main', guards: [Auth] },

	{ path: 'order', component: OrderListComponent, layout: 'Main', guards: [Auth] },
	{ path: 'order/subscription', component: OrderSubscriptionListComponent, layout: 'Main', guards: [Auth] },
	{ path: 'order/:id?', component: OrderDetailComponent, layout: 'Main', guards: [Auth] },

	{ path: 'notification', component: NotificationListComponent, layout: 'Main', guards: [Auth] },


	/*** Guest Site Dashboard ***/
	{ path 	: 'guest-access/:accessCode', component : GuestVerifyAccessCodeComponent, layout :'GuestAccess'},
	{ path 	: 'guest-access/site/dashboard/:accessCode', component : GuestSiteDashboardComponent, layout :'Guest'},
	{ path	: 'guest-access/site/report/:accessCode', component: GuestSiteAuditReportListComponent, layout: 'Guest'},
	{ path	: 'guest-access/site/report/view/:accessCode/:auditId', component: GuestSiteAuditHtmlReportListComponent, layout: 'Guest' },
	{ path	: 'guest-access/site/report/:accessCode/:archive', component: GuestSiteAuditReportListComponent, layout: 'Guest' },
	{ path	: 'guest-access/site/notification/:accessCode', component: GuestSiteNotificationListComponent, layout: 'Guest' },

	{ path	: 'generate-pdf/:orderId', component: GenerateMailOrderPDFComponent, layout: 'GuestAccess' },

	
	

	{ path: '*', component: ErrorComponent, layout: 'Main', guards: [Auth] },


];

const routes = () => {
	let routes = [];
	routeArr.forEach((item, index) => {

		if (item.layout == "Main") {
			routes.push(
				<Main key={index}
					guard={item.guards ? item.guards : ''}
					path={'/' + item.path}
					exact
					component={item.component}
				/>);
		}
		if (item.layout === "Login") {
			routes.push(
				<Login key={index}
					guard={item.guards ? item.guards : ''}
					path={'/' + item.path}
					exact
					component={item.component}
				/>);
		}
		if (item.layout === "Other") {
			routes.push(
				<Other key={index}
					guard={item.guards ? item.guards : ''}
					path={'/' + item.path}
					exact
					component={item.component}
				/>);
		}
		if (item.layout === "GuestAccess") {
			routes.push(
				<GuestAccess key={index}
					guard={item.guards ? item.guards : ''}
					path={'/' + item.path}
					exact
					component={item.component}
				/>);
		}
		if( item.layout == "Guest"){
			routes.push(
				<Guest key={index}
					guard={item.guards ? item.guards : ''}
					path={'/' + item.path}
					exact
					component={item.component}
				/>);
		}

	});
	// console.log(routes)
	return routes;
}

export default routes
