class globalConstantService {
	static apiModules = {
		'siteSetting': {
			url: process.env.REACT_APP_BASEURL + '/site-setting',
			methods: [
				{ name: 'create', type: 'post', url: '' },
				{ name: 'list', type: 'get', url: '' },

			]
		},
		'login': {
			url: process.env.REACT_APP_BASEURL + '/authentication/login',
			methods: [
				{ name: 'create', type: 'post', url: '' },
			]
		},
		'ticket': {
			url: process.env.REACT_APP_BASEURL + '/ticket',
			methods: [
				{ name: 'create', type: 'post', url: '' },
				{ name: 'list', type: 'get', url: '' },
				{ name: 'details', type: 'get', url: '' },
				{ name: 'update', type: 'put', url: '' },

			]
		},
		'user': {
			url: process.env.REACT_APP_BASEURL + '/user',
			methods: [
				{ name: 'create', type: 'post', url: '' },
				{ name: 'update', type: 'put', url: '' },
				{ name: 'list', type: 'get', url: '' },
				{ name: 'details', type: 'get', url: '' },
				{ name: 'delete', type: 'delete', url: '' }
			]
		},
		'setPassword': {
			url: process.env.REACT_APP_BASEURL + '/authentication/set-password',
			methods: [
				{ name: 'create', type: 'post', url: '' },
			]
		},
		'resetPassword': {
			url: process.env.REACT_APP_BASEURL + '/authentication/reset-password',
			methods: [
				{ name: 'create', type: 'post', url: '' },
			]
		},
		'logout': {
			url: process.env.REACT_APP_BASEURL + '/authentication/logout',
			methods: [
				{ name: 'create', type: 'post', url: '' },
			]
		},
		'changePassword': {
			url: process.env.REACT_APP_BASEURL + '/authentication/change-password',
			methods: [
				{ name: 'create', type: 'post', url: '' },
			]
		},
		'country': {
			url: process.env.REACT_APP_BASEURL + '/country',
			methods: [
				{ name: 'list', type: 'get', url: '' },
			]
		},
		'state': {
			url: process.env.REACT_APP_BASEURL + '/state',
			methods: [
				{ name: 'list', type: 'get', url: '' },
			]
		},
		'city': {
			url: process.env.REACT_APP_BASEURL + '/city',
			methods: [
				{ name: 'list', type: 'get', url: '' },
			]
		},
		'sites': {
			url: process.env.REACT_APP_BASEURL + '/site',
			methods: [ 
				{ name: 'create', type: 'post', url: '' },
				{ name: 'update', type: 'put', url: '' },
				{ name: 'list', type: 'get', url: '' },
				{ name: 'details', type: 'get', url: '' },
				{ name: 'delete', type: 'delete', url: '' }

			]
		},
		'siteFTP': {
			url: process.env.REACT_APP_BASEURL + '/site-ftp',
			methods: [
				{ name: 'create', type: 'post', url: '' },
				{ name: 'update', type: 'put', url: '' },
				{ name: 'list', type: 'get', url: '' },
				{ name: 'details', type: 'get', url: '' },
			]
		},
		'siteLoginInfo': {
			url: process.env.REACT_APP_BASEURL + '/site-login-info',
			methods: [
				{ name: 'create', type: 'post', url: '' },
				{ name: 'update', type: 'put', url: '' },
				{ name: 'list', type: 'get', url: '' },
				{ name: 'details', type: 'get', url: '' },
			]
		},
		'siteCriticalIssue': {
			url: process.env.REACT_APP_BASEURL + '/site-critical-issue',
			methods: [
				{ name: 'create', type: 'post', url: '' },
				{ name: 'update', type: 'put', url: '' },
				{ name: 'list', type: 'get', url: '' },
				{ name: 'details', type: 'get', url: '' },
			]
		},
		'siteTicket': {
			url: process.env.REACT_APP_BASEURL + '/site-ticket',
			methods: [
				{ name: 'create', type: 'post', url: '' },
				{ name: 'update', type: 'put', url: '' },
				{ name: 'list', type: 'get', url: '' },
				{ name: 'details', type: 'get', url: '' },
			]
		},
		'siteTicketReply': {
			url: process.env.REACT_APP_BASEURL + '/site-ticket-reply',
			methods: [
				{ name: 'create', type: 'post', url: '' },
				{ name: 'update', type: 'put', url: '' },
				{ name: 'list', type: 'get', url: '' },
				{ name: 'details', type: 'get', url: '' },
			]
		},
		'siteCategory': {
			url: process.env.REACT_APP_BASEURL + '/utility/sitecategory-list',
			methods: [
				{ name: 'list', type: 'get', url: '' },
			]
		},
		'proposalRole': {
			url: process.env.REACT_APP_BASEURL + '/proposal-role',
			methods: [
				{ name: 'create', type: 'post', url: '' },
				{ name: 'update', type: 'put', url: '' },
				{ name: 'list', type: 'get', url: '' },
				{ name: 'details', type: 'get', url: '' },
				{ name: 'delete', type: 'delete', url: '' }
			]
		},
		'proposalSection': {
			url: process.env.REACT_APP_BASEURL + '/proposal-section',
			methods: [
				{ name: 'create', type: 'post', url: '' },
				{ name: 'update', type: 'put', url: '' },
				{ name: 'list', type: 'get', url: '' },
				{ name: 'details', type: 'get', url: '' },
				{ name: 'delete', type: 'delete', url: '' }
			]
		},
		'proposalTeam': {
			url: process.env.REACT_APP_BASEURL + '/proposal-team',
			methods: [
				{ name: 'create', type: 'post', url: '' },
				{ name: 'update', type: 'put', url: '' },
				{ name: 'list', type: 'get', url: '' },
				{ name: 'details', type: 'get', url: '' },
				{ name: 'delete', type: 'delete', url: '' }
			]
		},
		'proposalTemplate': {
			url: process.env.REACT_APP_BASEURL + '/proposal-template',
			methods: [
				{ name: 'create', type: 'post', url: '' },
				{ name: 'update', type: 'put', url: '' },
				{ name: 'list', type: 'get', url: '' },
				{ name: 'details', type: 'get', url: '' },
				{ name: 'delete', type: 'delete', url: '' }
			]
		},
		'proposal': {
			url: process.env.REACT_APP_BASEURL + '/proposal',
			methods: [
				{ name: 'create', type: 'post', url: '' },
				{ name: 'update', type: 'put', url: '' },
				{ name: 'list', type: 'get', url: '' },
				{ name: 'details', type: 'get', url: '' },
				{ name: 'delete', type: 'delete', url: '' }
			]
		},
		'coupon': {
			url: process.env.REACT_APP_BASEURL + '/coupon',
			methods: [
				{ name: 'create', type: 'post', url: '' },
				{ name: 'update', type: 'put', url: '' },
				{ name: 'list', type: 'get', url: '' },
				{ name: 'details', type: 'get', url: '' },
				{ name: 'delete', type: 'delete', url: '' }
			]
		},
		'proposalAccepted': {
			url: process.env.REACT_APP_BASEURL + '/utility/proposal-accepted',
			methods: [
				{ name: 'details', type: 'get', url: '' },
			]
		},
		'proposalDeclined': {
			url: process.env.REACT_APP_BASEURL + '/utility/proposal-declined',
			methods: [
				{ name: 'update', type: 'put', url: '' },
			]
		},
		'proposalArchieved': {
			url: process.env.REACT_APP_BASEURL + '/utility/proposal-archieved',
			methods: [
				{ name: 'update', type: 'put', url: '' },


			]
		},
		'subscriptionPacakges': {
			url: process.env.REACT_APP_BASEURL + '/subscription-packages',
			methods: [
				{ name: 'list', type: 'get', url: '' },
				{ name: 'details', type: 'get', url: '' },
			]
		},
		'coupon': {
			url: process.env.REACT_APP_BASEURL + '/coupon',
			methods: [
				{ name: 'list', type: 'get', url: '' },
			]
		},
		'order': {
			url: process.env.REACT_APP_BASEURL + '/order',
			methods: [
				{ name: 'list', type: 'get', url: '' },
				{ name: 'create', type: 'post', url: '' },
				{ name: 'details', type: 'get', url: '' },
				{ name: 'update', type: 'put', url: '' },
			]
		},
		'userAddress': {
			url: process.env.REACT_APP_BASEURL + '/user-address',
			methods: [
				{ name: 'details', type: 'get', url: '' },
			]
		},
		'notification': {
			url: process.env.REACT_APP_BASEURL + '/notification',
			methods: [
				{ name: 'list', type: 'get', url: '' },
				{ name: 'update', type: 'put', url: '' },
			]
		},
		'updateNotificationReadStatus': {
			url: process.env.REACT_APP_BASEURL + '/utility/update-notification-read-status',
			methods: [
				{ name: 'update', type: 'put', url: '' },
			]
		},
		'content': {
			url: process.env.REACT_APP_BASEURL + '/content',
			methods: [
				{ name: 'details', type: 'get', url: '' },
			]
		},
		'marketingAssets': {
			url: process.env.REACT_APP_BASEURL + '/marketing-asset',
			methods: [


				{ name: 'list', type: 'get', url: '' },

			]
		},
		'becomePartner': {
			url: process.env.REACT_APP_BASEURL + '/become-partner',
			methods: [
				{ name: 'create', type: 'post', url: '' },

			]
		},
		'statusChange': {
			url: process.env.REACT_APP_BASEURL + '/status-change',
			methods: [
				{ name: 'update', type: 'put', url: '' }
			]
		},
		'removeTeamMember': {
			url: process.env.REACT_APP_BASEURL + '/utility/remove-team-member',
			methods: [
				{ name: 'delete', type: 'delete', url: '' }


			]
		},
		'proposalUpdateAcceptDeclineStatus': {
			url: process.env.REACT_APP_BASEURL + '/utility/proposal-update-accept-decline',
			methods: [
				{ name: 'update', type: 'put', url: '' },

			]
		},
		'proposalSendToClient': {
			url: process.env.REACT_APP_BASEURL + '/utility/send-proposal-client',
			methods: [
				{ name: 'update', type: 'get', url: '' },

			]
		},
		'forgotPassword': {
			url: process.env.REACT_APP_BASEURL + '/authentication/forgot-password',
			methods: [
				{ name: 'create', type: 'post', url: '' },

			]
		},
		'validForgotPasswordRequestOrNot': {
			url: process.env.REACT_APP_BASEURL + '/authentication/valid-forgot-password-request',
			methods: [
				{ name: 'details', type: 'get', url: '' },

			]
		},
		'proposalCoupon': {
			url: process.env.REACT_APP_BASEURL + '/proposal-coupon',
			methods: [
				{ name: 'create', type: 'post', url: '' },
				{ name: 'update', type: 'put', url: '' },
				{ name: 'list', type: 'get', url: '' },
				{ name: 'details', type: 'get', url: '' },
				{ name: 'delete', type: 'delete', url: '' }
			]
		},
		'commonNotificationActivity': {
			url: process.env.REACT_APP_BASEURL + '/utility/common-notification-activity',
			methods: [

				{ name: 'list', type: 'get', url: '' },

			]
		},
		'siteDahboardAuditInfo': {
			url: process.env.REACT_APP_BASEURL + '/utility/site-dashboard',
			methods: [

				{ name: 'details', type: 'get', url: '' },

			]
		},
		'siteNotification': {
			url: process.env.REACT_APP_BASEURL + '/site-notification',
			methods: [
				{ name: 'list', type: 'get', url: '' },
				{ name: 'update', type: 'put', url: '' },
			]
		},
		'updateSiteNotificationReadStatus': {
			url: process.env.REACT_APP_BASEURL + '/utility/update-site-notification-read-status',
			methods: [
				{ name: 'update', type: 'put', url: '' },
			]
		},
		'siteAuditList': {
			url: process.env.REACT_APP_BASEURL + '/audit',
			methods: [
				{ name: 'list', type: 'get', url: '' },
			]
		},
		'sitesCount': {
			url: process.env.REACT_APP_BASEURL + '/utility/user-site-count',
			methods: [
				{ name: 'list', type: 'get', url: '' },
			]
		},
		'proposalCreatedByUser': {
			
			url: process.env.REACT_APP_BASEURL + '/utility/proposal-created-by-user',
			methods: [
				{ name: 'list', type: 'get', url: '' },
			]
		},
		'updateCouponReadStatus': {
			
			url: process.env.REACT_APP_BASEURL + '/utility/update-coupon-read-status',
			methods: [
				{ name: 'update', type: 'put', url: '' },
			]
		},
		'audit'	: {
			url: process.env.REACT_APP_BASEURL + '/audit',
			methods: [	
				
				
				{ name: 'details', type: 'get', url: '' },
			]
		},
		'frontendDashboard'	: {
			url: process.env.REACT_APP_BASEURL + '/utility/frontend-dashboard',
			methods: [	
				
				
				{ name: 'details', type: 'get', url: '' },

			]
		},
		'sitesUserDashboardInfo': {
			url: process.env.REACT_APP_BASEURL + '/utility/site-user-dashboard-info',
			methods: [
				{ name: 'list', type: 'get', url: '' },

			]
		},
		'orderSubscription': {
			url: process.env.REACT_APP_BASEURL + '/utility/order-subscription',
			methods: [
				{ name: 'list', type: 'get', url: '' },

			]
		},
		'medias': {
			url: process.env.REACT_APP_BASEURL + '/media',
			methods: [	
				{ name: 'create', type: 'post', url: '' },
				{ name: 'list', type: 'get', url: '' },
				{ name: 'details', type: 'get', url: '' },
				{ name: 'delete', type: 'delete', url: '' }
			]
		},
		'auditData':{
			url: process.env.REACT_APP_BASEURL + '/audit-data',
			methods: [
				{ name: 'list', type: 'get', url: '' },
				
			]
		},
		'applyCoupon':{
			url: process.env.REACT_APP_BASEURL + '/utility/apply-coupon',
			methods: [
				{ name: 'create', type: 'post', url: '' },
				
			]
		},
		'offlinePaymentRequest':{
			url: process.env.REACT_APP_BASEURL + '/offline-payment-request',
			methods: [
				{ name: 'create', type: 'post', url: '' },
				
				
			]
		},
		'pluginSitInfo':{
			url: process.env.REACT_APP_BASEURL + '/utility/plugin-site-info',
			methods: [
				{ name: 'details', type: 'get', url: '' },
				
				
			]
		},
		'fetchClientSpecialPrice': {
			url: process.env.REACT_APP_BASEURL + '/utility/fetch-client-special-price',
			methods: [
				{ name: 'list', type: 'get', url: '' },


			]
		},
		'getAllCompletedAuditlist':{
			url: process.env.REACT_APP_BASEURL + '/utility/get-all-completed-audit-list',
			methods: [
				{ name: 'details', type: 'get', url: '' },


			]
		},
		'generateOrderPDF':{
			url: process.env.REACT_APP_BASEURL + '/utility/generate-order-pdf',
			methods: [
				{ name: 'details', type: 'get', url: '' }
			]
		},
		


	};

}
export default globalConstantService; 