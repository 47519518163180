import * as CryptoJS from 'crypto-js';

let encryptAES = (text) => {

	try{
		var secret = `${process.env.REACT_APP_Encryp_Key}`;
		var salt = CryptoJS.lib.WordArray.random(128 / 8);
		var iv = CryptoJS.lib.WordArray.random(128 / 8);
		var encrypted = CryptoJS.AES.encrypt(text, CryptoJS.PBKDF2(secret, salt, { keySize: 256 / 32, iterations: 100 }) /* key */, { iv: iv, padding: CryptoJS.pad.Pkcs7, mode: CryptoJS.mode.CBC })
		var transitmessage = salt.toString() + iv.toString() + encrypted.toString();
		return encodeURIComponent(transitmessage);
	}
	catch(e){
		var secret = `${process.env.REACT_APP_Encryp_Key}`;
		const encrypted = CryptoJS.AES.encrypt(text, secret);
		const encryptedData = encrypted.toString();
		return encodeURIComponent(encryptedData);
	}
	
	
};
let decryptAES = (data) => {

	try{
		var text = decodeURIComponent(data);
		var secret = `${process.env.REACT_APP_Encryp_Key}`;
		var key = CryptoJS.PBKDF2(secret, CryptoJS.enc.Hex.parse(text.substr(0, 32)) /* Salt */, { keySize: 256 / 32, iterations: 100 })
		var decrypted = CryptoJS.AES.decrypt(text.substring(64) /* encrypted */, key, { iv: CryptoJS.enc.Hex.parse(text.substr(32, 32)) /* iv */, padding: CryptoJS.pad.Pkcs7, mode: CryptoJS.mode.CBC })
		console.log(decrypted.toString(CryptoJS.enc.Utf8),'==decryptedDataOld')
		if(decrypted.toString(CryptoJS.enc.Utf8)){
			return decrypted.toString(CryptoJS.enc.Utf8);
		}
		else
		{
			var secret = `${process.env.REACT_APP_Encryp_Key}`;
			var text = decodeURIComponent(data);		

			const decrypted = CryptoJS.AES.decrypt(text, secret);
		
			const decryptedData = decrypted.toString(CryptoJS.enc.Utf8);
			console.log(decryptedData,'==decryptedData')
			return decryptedData;
		}

		
	}
	catch(e){
		var secret = `${process.env.REACT_APP_Encryp_Key}`;
		var text = decodeURIComponent(data);		

		const decrypted = CryptoJS.AES.decrypt(text, secret);
	
		const decryptedData = decrypted.toString(CryptoJS.enc.Utf8);
		console.log(decryptedData,'==decryptedData')
		return decryptedData;
	}
	
};
export const encrypdycrypService = {
	encryptAES,
	decryptAES
};