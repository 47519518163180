import React,{useContext,useEffect, useState} from 'react';
import HeaderView from './HeaderView';
import httpService from '../../../services/httpService';
import { commonJsFuncModule as commonJsObj } from '../../../utils/commonFunc';
import { history } from '../../../helpers/history';
import { store } from '../../../storage/store';


const HeaderComponent =() =>{ 
    const globalState = useContext(store);
    const { dispatch } = globalState;
    useEffect(()=>{
        dispatch({ type: 'storeData', payload:{} })
        
    },[])
  
    // let fetchApi = async () => {
    //     if (commonJsObj.getCurrentUserId()) {
    //         let notificationResponse = await httpService.setModule('notification').search({ user_id: commonJsObj.getCurrentUserId(), is_read: "0" });

    //         if (notificationResponse.res.data.status == 'success') {               
    //             fetchCouponCode(notificationResponse.res.data.data);
    //         }
    //         else{
    //             fetchCouponCode();
    //         }             
    //     }
    // }
    // let fetchCouponCode = async (notifyData) => {
    //     if (commonJsObj.getCurrentUserId()) {
    //         let promcodeNotify = await httpService.setModule('coupon').search({user_id:commonJsObj.getCurrentUserId(),is_read : "0", is_active:1 });
    //         if (promcodeNotify.res.data.status == 'success') {
    //            fetchSitePayementInfo(promcodeNotify.res.data.data,notifyData);
    //         }
    //         else{
    //             fetchSitePayementInfo(notifyData);
    //         }
    //     }
    // }
    let fetchApi = async()=>{
        let response = await httpService.setModule('commonNotificationActivity').search({ user_id: commonJsObj.getCurrentUserId() });

        dispatch({ type: 'storeData', payload:{"promoCodeInfo":response.res.data.data.promoCodeInfo,"notficationData":response.res.data.data.notificationInfo,"sitePaymentInfo": response.res.data.data.sitePaymentInfo} })
             
    }
    

    const logoutHandler = async (e) =>{
        e.preventDefault();
        let userId = commonJsObj.getCurrentUserId();
        await httpService.setModule('logout').create({user_id:userId});
        commonJsObj.removeTokenFromLocStorage();
        history.push('/login')

    }
    const getAllNotification = async()=>{
        
        let data = {
           
            "id":commonJsObj.getCurrentUserId(),
            "updateWith":"user_id",
            "user_id":commonJsObj.getCurrentUserId()
        }

       let notificationStatus = await httpService.setModule('updateNotificationReadStatus').update(data);
       if(notificationStatus.res.data.status === 'success') {
            dispatch({ type: 'storeData', payload:{"notficationData":notificationStatus.res.data.data,"promoCodeInfo":globalState.state.promoCodeInfo,"sitePaymentInfo": globalState.state.sitePaymentInfo} })
            
       }
       history.push('/notification')
    }
    useEffect(()=>{        
        fetchApi()        
    },[])
    

    return (
        <>
            <HeaderView logoutHandler={logoutHandler}  globalState={globalState} getAllNotification={getAllNotification}/>
        </>
        
    )
}

export default HeaderComponent;
