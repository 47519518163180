import React from 'react'
import FooterView from './FooterView';


function FooterComponent() {
    return (
        <FooterView/>
    )
}

export default FooterComponent
