import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { StateProvider } from './storage/store';

// import { TourProvider } from '@reactour/tour'

// const stepArr = [
//     {
//         selector: '.first-step',
//         content: () => (
//             <>
//                 <div className="ReactTour-arrow-up"></div>
//                 <div className='stepContiner'>
//                     <p className="step-title">Dashboard</p>
//                     <div className="steps">Steps 1 of 8</div>
//                     <p className='step-content'>This is your home dashboard screen, where you can see quick statistics of all your website accounts, tickets, and other relevant information. </p>
//                 </div>
//             </>
//         ),



//     },
//     {
//         selector: '.second-step',
//         content: () => (
//             <>
//                 <div className='stepContiner'>
//                     <p className="step-title">Select your site to view quick stats and info on that particular account.</p>
//                     <div className="steps">Steps 2 of 8</div>
//                     <p className='step-content'>You can select your site to get quick stats (Audit Data, Update Summary, Ban & Lockout Review, and performance grades) of that particular.</p>
//                     <p className='step-content'>You can also clock full dashboard to view much more comprehensive and detailed view.  You can see site notifications, get in touch with your auditor, manage tickets and manage your credentials etc.</p>
//                 </div>
//             </>
//         ),

//     },
//     {
//         selector: '.third-step',
//         content: () => (
//             <><div className="ReactTour-arrow-up"></div>
//                 <div className='stepContiner'>
//                     <p className="step-title">Sites</p>
//                     <div className="steps">Steps 3 of 8</div>
//                     <p className='step-content'>Get the list of all your site accounts, manage billing, renewals and other site related info.</p>
//                 </div>
//             </>
//         ),

//     },
//     {
//         selector: '.fourth-step',
//         content: () => (
//             <><div className="ReactTour-arrow-up"></div>
//                 <div className='stepContiner'>
//                     <p className="step-title">Become Agency Partner</p>
//                     <div className="steps">Steps 4 of 8</div>
//                     <p className='step-content'>You can explore our exclusive Agency Partner Programme for greater business opportunities and deals.</p>
//                 </div>
//             </>
//         ),

//     },
//     {
//         selector: '.fifth-step',
//         content: () => (
//             <><div className="ReactTour-arrow-up"></div>
//                 <div className='stepContiner'>
//                     <p className="step-title">Tickets</p>
//                     <div className="steps">Steps 5 of 8</div>
//                     <p className='step-content'>You can contact us anytime and every time you have queries. Our support team is truly passionate for the resolutions.</p>
//                 </div>
//             </>
//         ),

//     },
//     {
//         selector: '.sixth-step',
//         content: () => (
//             <>
//                 <div className='stepContiner'>
//                     <p className="step-title">Profile</p>
//                     <div className="steps">Steps 6 of 8</div>
//                     <p className='step-content'>Here, you can see & manage your Profile, Billing order information..</p>
//                 </div>
//             </>
//         ),

//     },
//     {
//         selector: '.seventh-step',
//         content: () => (
//             <>
//                 <div className='stepContiner'>
//                     <p className="step-title">Notifications</p>
//                     <div className="steps">Steps 7 of 8</div>
//                     <p className='step-content'>Never miss any notifications! Here you can see all user related notifications!</p>
//                 </div>
//             </>
//         ),

//     },
//     {
//         selector: '.eighth-step',
//         content: () => (
//             <><div className="ReactTour-arrow-up"></div>
//                 <div className='stepContiner'>
//                     <p className="step-title">Promo Codes</p>
//                     <div className="steps">Steps 8 of 8</div>
//                     <p className='step-content'>Keep an eye on our Promotional offers and deals here. It will be handy for your next renewal of website subscription.</p>
//                 </div>
//             </>
//         ),

//     }


// ]
// const handleTourClose = ()=>{
//    alert(1) 
// }


const Navigation = (props) => {
    const isLastStep = props.currentStep === props.steps.length - 1
    // console.log(isLastStep, '===')
    return (
        <div style={{}}>

            <button className={`${(isLastStep) ? 'tourNavBtn tourLast' : ' tourNavBtn '}`}
                onClick={() => {
                    if (isLastStep) {
                        props.setIsOpen(false)
                    } else {
                        props.setCurrentStep(s => s + 1)
                    }
                }}
            >
                {isLastStep ? <div className='tourDone'>Done</div> : <div className='tourNext'>Next</div>}
            </button>
        </div>
    )
}
ReactDOM.render(

    <StateProvider>
        {/* <TourProvider
            disableKeyboardNavigation={['esc']}
            disableInteraction={false}
            onRequestClose={handleTourClose}
            steps={stepArr}
            components={{ Navigation }}
            // afterOpen={disableBody}
            // beforeClose={enableBody}
            className={'tourContainHldr'}
            showNavigationNumber={false}
            showNavigation={true}
            showCloseButton={false}
            disableFocusLock={true}
            disablebackdropclick={true}
            styles={{
                badge: base => ({ ...base, background: '#ef5a3d' }),

                popover: base => ({
                    ...base,
                    background: '#fff',
                    borderRadius: 10,
                })
            }}
            scrollSmooth
        > */}

            <App />

        {/* </TourProvider> */}

    </StateProvider>
    ,
    document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
