import React, { Suspense, useEffect } from 'react';
import './App.css';
import { Router, Switch, BrowserRouter } from 'react-router-dom';
import { GlobalDebug  } from "./utils/remove-consoles.js";
import 'bootstrap/dist/css/bootstrap.min.css';
import './assets/css/style.scss';

import routes from './routes/routes';
import { history } from './helpers/history';

import withClearCache from "./ClearCache";

import { LastLocationProvider } from 'react-router-last-location';
let loadRoutes = routes();

const ClearCacheComponent = withClearCache(MainApp);

function App() {
	return <ClearCacheComponent />;
 
	 
}
function MainApp(props) {


	// localStorage.setItem('lang', 'en');
	useEffect(() => {
		(process.env.NODE_ENV === "production" ||
		 process.env.REACT_APP_ENV === "STAGING") &&
		  GlobalDebug(false);
	  }, []);

	  const debugStyle = 'color:red; font-size:30px; font-weight: bold; -webkit-text-stroke: 1px black;'
	  const debugH1 = 'color:red; font-size:55px; font-weight: bold; -webkit-text-stroke: 1px black;'
	  const debugp = 'color:black; font-size:25; font-weight: bold;'

	  

	  console.log("%c STOP!", debugH1);
	  console.log("%c Welcome to WordPromise", debugStyle);
	  console.log("%c This is a browser feature intended for developers. If someone told you to copy and paste something here to enable a WordPromise feature or 'hack' someone's account, it is a scam and will give them access to your WordPromise account.</>", debugp);
	
	return (
		<Suspense fallback={null}>
			<BrowserRouter >
				<Router history={history}>
					<LastLocationProvider>
						<Switch>
							{loadRoutes}
						</Switch>
					</LastLocationProvider>
				</Router>
			</BrowserRouter>
		</Suspense>
	);
}

export default App;
