import React, { useState, useEffect } from 'react';
import { Navbar } from 'react-bootstrap';
import { CountdownCircleTimer } from 'react-countdown-circle-timer'
import { Modal } from 'react-bootstrap';
import { jwtVerify } from '../../../helpers/jwt-verify';
import { useParams } from "react-router-dom";
// import moment from 'moment';
// import 'moment-timezone';

const GustHeaderComponent = () => {
    const [accessTimeout, setAccessTimeOut] = useState(false);
    const [backtoSiteUrl, setbacktoSiteUrl] = useState("");
    // const [baclToSiteUrlVisible,setBackTOSiteUrlVisible] = useState(false); 
    let { accessCode } = useParams();
    const hourSeconds = 960;

    let timezone = process.env.REACT_APP_TIME_TIMEZONE

    let codeInfo = jwtVerify.accessCodeVerifyDecode(accessCode);
    if (codeInfo.status == 200) {
       // console.log(codeInfo.data.site_url,'codeInfo.data.site_url')
       // setbacktoSiteUrl(codeInfo.data.site_url);
        
        let currentTimeStamp = Date.now() / 1000 | 0;
        let expireTimeStamp = codeInfo.data.exp;
        var s = codeInfo.data.site_url
        var remainingTime = expireTimeStamp - currentTimeStamp;
    }
    else {
        var remainingTime = 0;
        var s = '';
       // setBackTOSiteUrlVisible(true)
    }

    const renderTime = (time, siteUrl) => {

        // setbacktoSiteUrl(siteUrl)
// console.log(time,'time')
        if (time === 0) {
            setAccessTimeOut(true)
        }
        else {
            return (
                <div className="timer">
                    <div className="value">{time}</div>
                </div>
            );
        }

    };
    const getTimeMinutes = (time) => ((time % hourSeconds) / 60) | 0;

    useEffect(()=>{
        sessionStorage.removeItem("guestUserDashboardUrl")
    },[])
    return (
        <>
            <Navbar className="cr-header navbar" expand="lg">
                <div className="container">
                    <span className="cr-header__navbar-brand mr-5" to="/"><img alt="wordpromise" src="/assets/images/wp-logo-white.svg" className="brand-logo " /></span>
                    <span className='countDownCirleHld d-flex'>
                        <span className='text'>Access time left </span>
                       
                        <CountdownCircleTimer
                            isPlaying={true}
                            size={50}
                            strokeWidth={5}
                            colors="#efefef"
                            stroke="red"
                            duration={hourSeconds}
                            initialRemainingTime={remainingTime % hourSeconds}
                        
                        >
                            {({ elapsedTime, color }) => (
                               
                                <span style={{ color }}>
                                    {renderTime(getTimeMinutes(hourSeconds - elapsedTime),s )}
                                </span>
                            )}
                        </CountdownCircleTimer>

                    </span>
                </div>
            </Navbar>


            <Modal show={accessTimeout} className="notifyModal">


                <Modal.Body>
                    <img alt="wordpromise" src="/assets/images/wp-logo.svg" className="mx-auto d-block imgw" />
                    <p className='text-center mt-4 heading'>Oops! Your session has expired.</p>
                    {/* <p className='text-center description'>You can visit your <i><b>WordPress Admin {'>'} WordPromise plugin page</b></i> and click <b>“View Site Dashboard”</b> button again to activate WordPromise site dashbaord.</p> */}

                    <p className='text-center description'>To<b>“View Site Dashboard”</b> you can visit again your WordPress Admin {'>'} WordPromise plugin page.</p>

                    <a href={`${(sessionStorage.getItem("guestUserDashboardUrl") !=undefined) ? sessionStorage.getItem("guestUserDashboardUrl") : ""}`} className='btn btn-success w-100'>{'<'} Back to dashboard  </a>


                </Modal.Body>


            </Modal>

        </>

    )
}

export default GustHeaderComponent;
