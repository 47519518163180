import React from 'react';
import { withRouter } from 'react-router-dom'
import './footer.scss';



function FooterView() {

    return (


        <footer className="footer mt-5 pb-2">   
            <div className='container'>
                <div className='row'>
                    <div className='col-lg-8 mb-lg-0'>
                        <p className="copyright m-0">Copyright {new Date().getFullYear()}. All Right Reserved by <a href="https://www.dreamdax.com/" target='_blank'>Dreamdax Technology Pvt. Ltd.</a></p>
                    </div>
                    <div className='col-lg-4 mb-lg-0'>

                        <p className='copyRightOtherContent'><a href="https://www.wordpromise.com/gdpr-policy/" target="_blank">GDPR Policy</a> <a href="https://www.wordpromise.com/privacy-policy-2/" target="_blank">Privacy Policy</a> <a href="https://www.wordpromise.com/terms-condition/" target="_blank">Terms & Conditions</a></p>

                    </div>
                </div>
            </div>

            
        </footer>



    ) 
}

export default withRouter(FooterView)
 