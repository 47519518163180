import React,{useContext,useEffect, useState} from 'react';
import CommonNotificationActivityView from './CommonNotificationActivityView';
import httpService from '../../../services/httpService';
import { commonJsFuncModule as commonJsObj } from '../../../utils/commonFunc';
import { store } from '../../../storage/store';
import { history } from '../../../helpers/history';
import CustLoader from '../../../utils/loader/CustLoader';



const CommonNotificationActivity =(props) =>{ 
    const globalState = useContext(store);   
    const { dispatch } = globalState;
    const [pHModelStatus, setPHModelStatus] = useState(false)
    const [phNotifyId,setPhNotifyId] = useState()

    const [pHDAModelStatus, setPHDAModelStatus] = useState(false)
    const [phDANotifyId,setPhDANotifyId] = useState()

    const [UDAModelStatus, setUDAModelStatus] = useState(false)
    const [UDAANotifyId,setUDANotifyId] = useState()

    

    const [loderFlag, setLoaderFlag] = useState(false);    

    let showLoader = () => {
        setLoaderFlag(true);
    }
    let hideLoader = () => {
        setLoaderFlag(false);
    }
    const closeNotification = async (id, type)=>{
        let data = {
            "id" : id,
            "updateWith":"id",
            "user_id":commonJsObj.getCurrentUserId()
        }

       let notificationStatus = await httpService.setModule('updateNotificationReadStatus').update(data);
       if(notificationStatus.res.data.status === 'success') {
            dispatch({ type: 'storeData', payload:{"notficationData":notificationStatus.res.data.data,"promoCodeInfo":globalState.state.promoCodeInfo,"sitePaymentInfo":globalState.state.sitePaymentInfo } })
            if(type === 'PC'){
                history.push('/offers')
            }
            if(type === 'ST'){
                history.push('/ticket')
            }
       }

    }   
    const logoutHandler = async (e,id, type) =>{
        showLoader()
        e.preventDefault();
        closeNotification(id)
       
        setTimeout(async ()=>{
            let userId = commonJsObj.getCurrentUserId();
            await httpService.setModule('logout').create({user_id:userId});
            setPHModelStatus(false)
            setUDAModelStatus(false)
            setPHDAModelStatus(false)
            hideLoader()
            commonJsObj.removeTokenFromLocStorage();
            if(type === 'login-disable'){
                history.push('/help')
            }
            else{
                history.push('/login')    
            }
            
        },800)
        

    }
    const displayNotificationModal = (id) => {
        setTimeout(() => {
            setUDAModelStatus(false)
            setPHDAModelStatus(false)
            setPHModelStatus(true)
            setPhNotifyId(id)
            


        }, 2000)

        // alert(1)
    }
    const displayDeActivatedNotificationModal = (id) => {
        setTimeout(() => {
            setUDAModelStatus(false)
            setPHDAModelStatus(true)
            setPHModelStatus(false)
            
            setPhDANotifyId(id)
        }, 2000)

        // alert(1)
    }
    const userDeactivatedNotificatinModal = (id) => {
        setTimeout(() => {
            setUDAModelStatus(true)
            setPHDAModelStatus(false)
            setPHModelStatus(false)
            setUDANotifyId(id)
        }, 1000)

        // alert(1)
    }
   
    

    return (
        <>
         {loderFlag ? <CustLoader /> : ''}
         {!loderFlag && <>
            <CommonNotificationActivityView  displayNotificationModal={displayNotificationModal} pHModelStatus={pHModelStatus} closeNotification={closeNotification} globalState={globalState} logoutHandler={logoutHandler} phNotifyId={phNotifyId} displayDeActivatedNotificationModal={displayDeActivatedNotificationModal} pHDAModelStatus={pHDAModelStatus} phDANotifyId={phDANotifyId} UDAModelStatus={UDAModelStatus} UDAANotifyId={UDAANotifyId} userDeactivatedNotificatinModal={userDeactivatedNotificatinModal}/>
            </>  }
        </>
        
    )
}

export default CommonNotificationActivity;
