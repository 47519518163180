import React, { useState, useEffect } from "react";
import packageJson from "../package.json";
import moment from "moment";

const buildDateGreaterThan = (latestDate, currentDate) => {
  const momLatestDateTime = moment(latestDate);
  const momCurrentDateTime = moment(currentDate);

  if (momLatestDateTime.isAfter(momCurrentDateTime)) {
    return true;
  } else {
    return false;
  }
};

function withClearCache(Component) {
  function ClearCacheComponent(props) {
    const [isLatestBuildDate, setIsLatestBuildDate] = useState(false);

    const callMetaJson = () => {
      return fetch("/meta.json")
    }
    useEffect(() => {
      const fetchData = async () => {
        let metaJsonResponse = await callMetaJson()
        // console.log(metaJsonResponse, 'metaJsonResponse')
        if (metaJsonResponse) {
          const latestVersionDate = metaJsonResponse.buildDate;
          const currentVersionDate = packageJson.buildDate;

          const shouldForceRefresh = buildDateGreaterThan(
            latestVersionDate,
            currentVersionDate
          );
          console.log(shouldForceRefresh, 'shouldForceRefresh')
          if (shouldForceRefresh) {
            console.log(process.env.NODE_ENV,'process.env.NODE_ENV')
            setIsLatestBuildDate(false);
              refreshCacheAndReload();

          } else {
            setIsLatestBuildDate(true);
          }
        }

      }
      fetchData()

    }, []);

    const refreshCacheAndReload = () => {
      console.log(caches, 'refreshCacheAndReload', caches.keys())
      if (caches) {
        // Service worker cache should be cleared with caches.delete()
        caches.keys()
          .then((names) => {
            if (names.length > 0) {
              console.log('deleted', names, '==names')
              for (const name of names) {
                caches.delete(name);
              }
              console.log('window load')
              // delete browser cache and hard reload
              window.location.reload(true);
            }
            else {
              console.log('==cache clean==')
            }


          });

      }



    };

    console.log(isLatestBuildDate, '==isLatestBuildDate')
    return (
      <React.Fragment>
       <Component {...props} />
      </React.Fragment>
    );
  }

  return ClearCacheComponent;
}

export default withClearCache;
