import React from 'react'
import { ToastsContainer, ToastsStore, ToastsContainerPosition } from 'react-toasts';
import GustHeaderComponent from '../Common/header/GustHeaderComponent';
import FooterComponent from '../Common/footer/FooterComponent';



const MainLayout = (props) => {


    return (
        <React.Fragment>
            
                <main className="cr-app bg-light">
                    <GustHeaderComponent />
                    <section className="main-content-wrapper container d-flex flex-column">
                        <div className="content-wrap-inner">
                            {props.children}
                        </div>
                        <FooterComponent />
                    </section>
                </main>
                <ToastsContainer store={ToastsStore} position={ToastsContainerPosition.TOP_CENTER} />

        </React.Fragment>
    );
}

export default MainLayout
