import React from 'react';
import { withRouter } from 'react-router-dom';
import { Link, NavLink } from 'react-router-dom';
import { Dropdown, Navbar } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBell, faChartBar, faListAlt, faTools, faUserCog, faAngleRight, faClock } from '@fortawesome/free-solid-svg-icons';
import './header.scss';
import { encrypdycrypService } from '../../../helpers/encryp-dycryp';
import { commonJsFuncModule as commonJsObj } from '../../../utils/commonFunc';
import { couponIcon, supportTicketIcon } from '../../Common/svgIconList';
import moment from "moment";
import 'moment-timezone';


const HeaderView = (props) => {
    const userName = commonJsObj.getUserInfo();
    let userNameDetail = (userName) ? encrypdycrypService.decryptAES(userName.user.userDetail.name).charAt(0).toUpperCase() : ''
    let timezone = process.env.REACT_APP_TIME_TIMEZONE
    return (
        <Navbar className="cr-header navbar" expand="lg">
            <div className="container">
                <Link className="cr-header__navbar-brand mr-5" to="/"><img alt="wordpromise" src="/assets/images/wp-logo-white.svg" className="brand-logo " /></Link>
                <Navbar.Toggle aria-controls="basic-navbar-nav" />
                <Navbar.Collapse id="basic-navbar-nav">
                    <ul className="navbar-nav mr-auto">
                        <li className="nav-item first-step">
                            <NavLink to="/dashboard" className="nav-link " activeClassName="active"><FontAwesomeIcon icon={faChartBar} /><span>Dashboard</span></NavLink>
                        </li>
                        <li className="nav-item third-step">
                            <NavLink to="/sites" className="nav-link" activeClassName="active" ><FontAwesomeIcon icon={faListAlt} /><span>Sites</span></NavLink>
                        </li>

                        {
                            (userName.user.is_reseller == 0) ? <li className="nav-item fourth-step">
                                <NavLink to="/become-an-agency-partner" className="nav-link" activeClassName="active"><FontAwesomeIcon icon={faUserCog} /><span>Agency Partners Request</span></NavLink>
                            </li> : ''
                        }

                        {
                            (userName.user.is_reseller == 1) ? <li className="nav-item">
                                <Dropdown className="nav-dropdown">
                                    <Dropdown.Toggle variant="success" id="dropdown-basic" activeClassName="active">
                                        <FontAwesomeIcon icon={faTools} /><span>Partner Hub</span>
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu>
                                        

                                        <div className="sub-menu">
                                            <Dropdown.Item className="sub-menu-toggle">WordPromise Proposal Builder (WPB) <FontAwesomeIcon icon={faAngleRight} /></Dropdown.Item>
                                            <div className="sub-menu-item">
                                                <Dropdown.Item as={Link} to="/proposal">Proposal</Dropdown.Item>
                                                <div class="sub-menu">
                                                    <Dropdown.Item className="sub-menu-toggle">Settings <FontAwesomeIcon icon={faAngleRight} /></Dropdown.Item>
                                                    <div className="sub-menu-item">
                                                        <Dropdown.Item as={Link} to="/proposal-team">Manage Team</Dropdown.Item>
                                                        <Dropdown.Item as={Link} to="/proposal-section">Manage Custom Section</Dropdown.Item>
                                                        <Dropdown.Item as={Link} to="/media-library">Manage Media Library</Dropdown.Item>
                                                        {/* <Dropdown.Item  as={Link} to="/coupon">Manage Promo Code</Dropdown.Item>  */}
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                        <Dropdown.Item as={Link} to="/marketing-asset">Marketing Assets</Dropdown.Item>
                                    </Dropdown.Menu>
                                </Dropdown>
                            </li> : ""
                        }

 

                        <li className="nav-item mr-4 offerHld eighth-step">
                            <NavLink to="/offers" className="nav-link position-relative" activeClassName="active">{couponIcon}
                                <span>Promo Code
                                    {
                                        (props.globalState && props.globalState.state.promoCodeInfo != undefined) ? (props.globalState.state.promoCodeInfo) ? <div className='headerNotfiyHld'>{props.globalState.state.promoCodeInfo}</div> : "" : ""

                                    }
                                </span>
                            </NavLink>
                        </li>

                    </ul>
                    <ul className="cr-header__nav-right navbar-nav">
                        <li className="nav-item">
                            <ul className="navbar-nav nav-color-gray border-right">


                                <li className="nav-item no-hover-eff customersupport">
                                    <NavLink to="/ticket" className="nav-link st fifth-step" activeClassName="active">
                                        {supportTicketIcon} Support Ticket(s)
                                    </NavLink>
                                </li>
                                <li className="nav-item no-hover-eff prl notificationHld seventh-step">
                                    <span className="nav-link hrdLink" onClick={() => props.getAllNotification()}><FontAwesomeIcon icon={faBell} />
                                        {
                                            (props.globalState && props.globalState.state.notificationInfo) ? (props.globalState.state.notificationInfo.length) ? <span className='headerNotfiyHld supportTicketN'>{props.globalState.state.notificationInfo.length}</span> : "" : ""


                                        }</span>
                                    {/* <Link to="/notification" className="nav-link">

                                    </Link> */}
                                </li>
                            </ul>
                        </li>


                        <li className="nav-item no-hover-eff sixth-step">
                            <Dropdown className="nav-dropdown userNameFirstL-drop">
                                <Dropdown.Toggle variant="success" id="dropdown-basic">
                                    <span className="userNameFirstL">{(userNameDetail) ? userNameDetail : 'U'}</span>
                                </Dropdown.Toggle>
                                <Dropdown.Menu className="dropdown-menu-right">
                                <li className='userHeader'>
                                            {
                                                (userName.user.userDetail.profile_image) ? <><img src={userName.user.userDetail.profile_image} className="img-circle" /></> : <img src="/assets/images/profile-no-image.jpg" alt="" className="img-circle" />

                                            }
                                            <p className="mt-2">
                                                <b>{encrypdycrypService.decryptAES(userName.user.userDetail.name)}</b>
                                                {/* <small>Member Since: {moment(userName.user.created_at).format('MMMM Y')}</small> */}
                                                <small className='headerTimeInfo mt-3'>
                                                    <FontAwesomeIcon icon={faClock} />&nbsp; Current Server Time:
                                                    <p>{moment(moment().format('MM/DD/YYYY HH:mm:ss')).tz(timezone).format('MMMM Do YYYY, h:mm a')}</p>

                                                </small>
                                            </p>

                                        </li>
                                    <Dropdown.Item as={Link} to="/my-profile">My Profile</Dropdown.Item>
                                    <Dropdown.Item as={Link} to="/change-password">Change Password</Dropdown.Item>
                                    <Dropdown.Item as={Link} to="/order/subscription">Billing</Dropdown.Item>
                                    <Dropdown.Item as={Link} to="/logout" onClick={(e) => props.logoutHandler(e)}>Logout</Dropdown.Item>
                                    
                                </Dropdown.Menu>
                            </Dropdown>
                        </li>

                    </ul>
                </Navbar.Collapse>
            </div>
        </Navbar>
    );
};


export default withRouter(HeaderView);
